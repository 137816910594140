import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';
import { format } from 'date-fns';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import OrderModalComponent from 'src/components/SchedulePage/OrderModal/OrderModalComponent';
import { useAsyncService } from 'src/contexts/AsyncServiceContext';
import { useUser } from 'src/contexts/UserContext';
import {
  prependPendingOrder,
  deleteOrder,
  setSelectedOrder,
  setUnsaved,
  clearFilteredInspectorsNotifications,
  notifyFilteredInspectors,
} from 'src/store/ducks/schedule';
import { showToast } from 'src/store/ducks/toasts';

const moment = extendMoment(Moment);

const OrderModalContainer = ({ orderAlreadyBeingEditedByAnotherUser }) => {
  const [busy, setBusy] = useState(true);
  const [error, setError] = useState(false);
  const [isInTimeline, setIsInTimeline] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [currentOrderHistoric, setCurrentOrderHistoric] = useState([]);
  const [annotationUpdate, setAnnotationUpdate] = useState(0);

  const user = useUser();

  const canShowAnnotation = user?.authRole === 'ROLE_FRANCHISEE';

  const dispatch = useDispatch();
  const { date, orders, selectedOrder, ordersThatAreBeingEdited } = useSelector(state => ({
    date: state.schedule.date,
    orders: state.schedule.orders,
    selectedOrder: state.schedule.selectedOrder,
    ordersThatAreBeingEdited: state.schedule.ordersThatAreBeingEdited,
  }));

  const intl = useIntl();

  const { emit } = useAsyncService();

  const me = JSON.parse(window.sessionStorage.getItem('me'));

  const handleEmitOrderEditingHasBeenFinished = useCallback(
    (orderId = null) => {
      const isMeEditingTheOrder = ordersThatAreBeingEdited.find(orderThatAreBeingEdited => {
        return (
          orderThatAreBeingEdited?.order?.order?.public_id === orderId &&
          orderThatAreBeingEdited?.sender?.id === me?.uniqueId
        );
      });

      if (orderId && isMeEditingTheOrder) {
        emit({
          data: {
            channel: `panel_${me.panelId}`,
            event: 'SCHEDULE.ORDER_EDITING_HAS_BEEN_FINISHED',
            data: {
              order: {
                order: {
                  public_id: orderId,
                },
              },
              sender: {
                id: me?.uniqueId,
                who: me?.name,
                when: format(new Date(), 'yyyy-MM-dd HH:mm'),
              },
            },
          },
        });
      }
    },
    [emit, me, ordersThatAreBeingEdited]
  );

  const handleEmitOrderIsBeingUpdatedEvent = useCallback(
    (order = null) => {
      if (order) {
        const isAlreadyBeenEditedByAnotherUser = ordersThatAreBeingEdited.find(orderThatAreBeingEdited => {
          return (
            orderThatAreBeingEdited?.order?.order?.public_id === order?.order?.public_id &&
            orderThatAreBeingEdited?.sender?.id !== me?.uniqueId
          );
        });

        if (!isAlreadyBeenEditedByAnotherUser) {
          emit({
            data: {
              channel: `panel_${me.panelId}`,
              event: 'SCHEDULE.ORDER_IS_BEING_UPDATED',
              data: {
                order,
                sender: {
                  id: me?.uniqueId,
                  who: me?.name,
                  when: format(new Date(), 'yyyy-MM-dd HH:mm'),
                },
              },
            },
          });
        }
      }
    },
    [emit, me, ordersThatAreBeingEdited]
  );

  useEffect(() => {
    const timelineOrder = orders.reduce(
      (acc, cur) => (cur.order.public_id === selectedOrder.orderId ? { ...cur } : acc),
      null
    );
    const source = API.CancelToken.source();

    setIsInTimeline(!!timelineOrder);

    const parseOrder = (order) => {
      const { form } = order;
      const { schedule } = form || {};
    
      return {
        order: {
          public_id: selectedOrder?.orderId,
          code: order?.code,
          type: form?.inspection_type,
          client: form?.clients?.client,
          keys_location: form?.accessInformation?.keysLocation || intl.formatMessage({ id: 'UNINFORMED' }),
          ...form?.additionals,
          ...form?.details,
          ...form?.address,
          address_id: order?.address_id,
          order_type_id: order?.order_type_id,
          access_information: form?.accessInformation,
          property_access: form?.propertyAccess,
          accompanied_inspection: form?.accompaniedInspection,
          attachments: form?.attachments,
          previous_inspection: form?.previous_inspection,
          building_type: form?.details?.building_type,
          allowed_at_date: form?.details?.allowed_at_date,
          furnished: form?.details?.furnished,
          modality: form?.additionals?.modality,
          urgency: form?.additionals?.urgency,
          created_at: order?.created_at?.date,
          details: form?.details?.details,
          annotation: order?.annotation,
          search_tokens: order?.search_tokens,
          status: order?.status,
        },
        schedule: {
          who: {
            inspector_id: selectedOrder?.inspectorId || timelineOrder?.schedule?.who?.inspector_id || schedule?.inspector_id || '',
          },
          when: {
            date: moment(schedule?.when?.date || date).format('DD/MM/YYYY'),
            start_time: selectedOrder?.startTime || timelineOrder?.schedule?.when?.start_time || schedule?.start_time || '',
            end_time: timelineOrder?.schedule?.when?.end_time || schedule?.end_time || '',
          },
          flextime: timelineOrder?.schedule?.flextime || schedule?.flextime || false,
          observation: timelineOrder?.schedule?.observation || schedule?.observation || '',
        },
      };
    };

    const fetchOrder = async () => {
      try {
        setBusy(true);

        if (selectedOrder?.orderId && /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(selectedOrder?.orderId)) {
          const { data } = await API.get(`/view/orders/${selectedOrder.orderId}`, { cancelToken: source.token });
          const parsedOrder = parseOrder(data);
          setCurrentOrder(parsedOrder);
          dispatch(
            notifyFilteredInspectors({
              address_id: data?.address_id,
              order_type_id: data?.order_type_id,
            })
          );
        }
      } catch (err) {
        setError(true);

        if (axios.isCancel(err)) return;
      } finally {
        setBusy(false);
      }
    };

    const fetchOrderHistoric = async () => {
      try {
        if (selectedOrder?.orderId) {
          const { data } = await API.get(
            `/activity-log/orders/${selectedOrder.orderId}/actions?types[]=INSPECTOR_CHECKIN&types[]=INSPECTOR_CHECKOUT&types[]=INSPECTOR_DO_INSPECTION_LATER&types[]=INSPECTOR_DO_INSPECTION_NOW&types[]=INSPECTOR_GOING_TO_PICK_UP_KEYS&types[]=INSPECTOR_GOING_TO_RETURN_KEYS&types[]=INSPECTOR_PICK_UP_KEYS&types[]=INSPECTOR_RETURN_KEYS`
          );

          const formatedHistoric = data.map(d => {
            const itemDate = moment(d.created_at.date);
            return {
              ...d,
              humanDate: itemDate.fromNow(),
              date: itemDate.format('DD/MM/YYYY [às] HH:mm'),
            };
          });
          setCurrentOrderHistoric(formatedHistoric);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchOrder();
    fetchOrderHistoric();

    return () => {
      if (source) source.cancel();
    };
  }, [date, dispatch, intl, orders, selectedOrder, annotationUpdate]);

  useEffect(() => {
    if (currentOrder) {
      handleEmitOrderIsBeingUpdatedEvent(currentOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder]);

  const unassign = async ({ order, schedule }) => {
    dispatch(deleteOrder(order?.public_id));
    dispatch(
      prependPendingOrder({
        client: order?.client,
        code: order?.code,
        identifier: order?.building_id,
        id: order?.public_id,
        type: order?.type,
        credits: order?.credits,
        address_id: order?.address_id,
        order_type_id: order?.order_type_id,
        building_type: order?.building_type,
        allowed_at_date: order?.allowed_at_date,
        search_tokens: order?.search_tokens,
        accompanied_inspection: order?.accompanied_inspection,
        furnished: order?.furnished,
        modality: order?.modality,
        urgency: order?.urgency,
        created_at: order?.created_at,
        access_information: order?.access_information,
        details: order?.details,
        status: order?.status,
        city: order?.city,
        neighborhood: order?.neighborhood,
        street: order?.street,
        number: order?.number,
        complement: order?.complement,
        state: order?.state,
        zipcode: order?.zipcode,
      })
    );
    dispatch(setSelectedOrder(null));
    dispatch(setUnsaved(true));
    dispatch(
      showToast({
        type: 'success',
        title: intl.formatMessage({ id: 'SUCCESS' }),
        text: intl.formatMessage({ id: 'INSPECTOR_ORDER_UNASSIGNED_SUCCESS' }),
        duration: 3000,
      })
    );

    try {
      await API.post('/scheduler/sync', {
        channel: `panel_${me?.panelId}`,
        event: 'SCHEDULE.ORDER_UNASSIGNED',
        data: {
          order,
          schedule,
          sender: {
            id: me?.uniqueId,
            who: me?.name,
            when: format(new Date(), 'yyyy-MM-dd HH:mm'),
          },
        },
      });
    } catch {
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'UNABLE_SAVE_CALENDAR_DATA_ERROR_MESSAGE' }),
          duration: 3000,
        })
      );
    }
  };

  const closeModal = useCallback(() => {
    dispatch(setSelectedOrder(null));
    dispatch(clearFilteredInspectorsNotifications());

    if (currentOrder?.order?.public_id) {
      handleEmitOrderEditingHasBeenFinished(currentOrder?.order?.public_id);
    }
  }, [currentOrder, dispatch, handleEmitOrderEditingHasBeenFinished]);

  const openAttachment = useCallback(
    (remoteName, originalName, extension) => {
      API.get(`/orders/${selectedOrder?.orderId}/attachments`, {
        params: {
          original_name: originalName,
          remote_name: remoteName,
          extension,
        },
      }).then(res => {
        window.open(res.data?.url, '_blank');
      });
    },
    [selectedOrder]
  );

  const refreshHandler = () => {
    setAnnotationUpdate(annotationUpdate + 1);
  };

  return (
    <OrderModalComponent
      isBusy={busy}
      hasErrors={error}
      confirmRemove={confirmRemove}
      setConfirmRemove={setConfirmRemove}
      isInTimeline={isInTimeline}
      order={currentOrder?.order}
      historic={currentOrderHistoric}
      schedule={currentOrder?.schedule}
      closeModal={closeModal}
      openAttachment={openAttachment}
      unassign={unassign}
      handleEmitOrderEditingHasBeenFinished={handleEmitOrderEditingHasBeenFinished}
      orderAlreadyBeingEditedByAnotherUser={orderAlreadyBeingEditedByAnotherUser}
      canShowAnnotation={canShowAnnotation}
      refreshHandler={refreshHandler}
    />
  );
};

OrderModalContainer.propTypes = {
  orderAlreadyBeingEditedByAnotherUser: PropTypes.object,
};

OrderModalContainer.defaultProps = {
  orderAlreadyBeingEditedByAnotherUser: null,
};

export default OrderModalContainer;
