import React from 'react';

import PropTypes from 'prop-types';

import { Spinner } from 'src/components/common';
import FullscreenSpinner from 'src/components/common/FullscreenSpinner';
import Section from 'src/components/Page/Section';
import DateInfo from 'src/components/SchedulePage/DateInfo';
import DateSwitch from 'src/components/SchedulePage/DateSwitch';
import OrderModal from 'src/components/SchedulePage/OrderModal';
import classes from 'src/components/SchedulePage/style.module.scss';
import Timeline from 'src/components/SchedulePage/Timeline';
import sendToDevicesIcon from 'src/images/icons/send-to-devices.svg';

const SchedulePageComponent = ({
  selectedOrder,
  handleSubmit,
  isBusy,
  isButtonDisabled,
  orderAlreadyBeingEditedByAnotherUser,
  isUnsaved,
  isDisconnected,
  intl,
  sidebarCollapsed,
}) => (
  <Section padding>
    {isDisconnected && (
      <div className={`${classes.pageDisabled}  ${sidebarCollapsed ? classes.sidebarCollapsed : ''}`}>
        <div className={`${classes.draftBorder} ${classes.isTop} ${sidebarCollapsed ? classes.sidebarCollapsed : ''}`}>
          Verifique a sua conexão com a internet. Tentando se reconectar...
        </div>
      </div>
    )}

    {isUnsaved && (
      <div className={`${classes.draftBorder} ${classes.isTop} ${sidebarCollapsed ? classes.sidebarCollapsed : ''}`}>
        Alguns pedidos ainda não foram enviados aos dispositivos.
      </div>
    )}

    {isBusy && (
      <div className={classes.busy}>
        <FullscreenSpinner text={intl.formatMessage({ id: 'WAIT_THE_ORDER_ARE_SENT_TO_INSPECTORS_MESSAGE' })} />
      </div>
    )}

    <div className={classes.top}>
      <div className={classes.dateContainer}>
        <DateInfo />
        <DateSwitch />
      </div>

      <div className={classes.action}>
        <button type="button" onClick={handleSubmit} disabled={isButtonDisabled}>
          {isButtonDisabled ? (
            <div className={classes.spinnerWrapper}>
              <Spinner color="inherit" />
            </div>
          ) : (
            <>
              <img src={sendToDevicesIcon} alt="Ícone flecha" />

              {intl.formatMessage({ id: 'SEND_TO_DEVICES' })}
            </>
          )}
        </button>
      </div>
    </div>

    <Timeline />

    {selectedOrder && (
      <OrderModal
        orderId={selectedOrder.orderId}
        inspectorId={selectedOrder.inspectorId}
        startTime={selectedOrder.startTime}
        orderAlreadyBeingEditedByAnotherUser={orderAlreadyBeingEditedByAnotherUser}
      />
    )}
  </Section>
);

SchedulePageComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isBusy: PropTypes.bool.isRequired,
  isButtonDisabled: PropTypes.bool.isRequired,
  isDisconnected: PropTypes.bool.isRequired,
  selectedOrder: PropTypes.object,
  intl: PropTypes.object.isRequired,
  orderAlreadyBeingEditedByAnotherUser: PropTypes.object,
  isUnsaved: PropTypes.bool,
  sidebarCollapsed: PropTypes.bool.isRequired,
};

SchedulePageComponent.defaultProps = {
  selectedOrder: null,
  orderAlreadyBeingEditedByAnotherUser: undefined,
  isUnsaved: false,
};

export default SchedulePageComponent;
