import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Add, Search } from '@material-ui/icons';
import PropTypes from 'prop-types';

import classes from 'src/components/OrderOptionList/OrderOptionsList.module.scss';
import { orderType } from 'src/constants';

import OrderCardType from '../OrderCardType/OrderCardType';
import OrderCardService from '../OrderCardService/OrderCardService';
import { postCheckUser } from 'src/services/izeeService';

import config from '../../config';
import debounce from 'lodash.debounce';

const typeConfiguration = JSON.parse(window.sessionStorage.getItem('orderTypeConfigurations'));

const OrderContainer = ({ title, hidden, children, length }) => {
  return (
    <div className={classes.orderCardType} style={{ display: hidden ? 'none' : '' }}>
      <div>
        <h3 className={classes.orderOptionListSubtitle}>
          <FormattedMessage id={title} /> {length && `(${length})`}
        </h3>
      </div>
      <div className={classes.container}>{children}</div>
    </div>
  );
};

const OrderMainRequests = ({ features, handleOrderOptionSelect, withDescription, hidden }) => {
  return (
    <OrderContainer title="orderOptionList.mainRequests" hidden={hidden}>
      {features
        .filter(feature => feature === 'VISTORIA_ENTRADA' || feature === 'VISTORIA_SAIDA')
        .map(option => {
          return (
            <OrderCardType
              key={option}
              type={orderType.get(option)}
              hasHelp={false}
              withDescription={withDescription}
              handleClick={() => handleOrderOptionSelect(orderType.get(option))}
            />
          );
        })}
    </OrderContainer>
  );
};

const OrderAllTypes = ({ features, handleOrderOptionSelect, withDescription, hasHelp, hidden }) => {
  const total = features.filter(feature => {
    if (feature.indexOf('VISTORIA') !== -1) {
      return feature;
    }
    return false;
  });
  return (
    <OrderContainer title="orderOptionList.allTypes" hidden={hidden} length={total.length}>
      {features
        .filter(feature => {
          if (feature.indexOf('VISTORIA') !== -1) {
            return feature;
          }
          return false;
        })
        .map(option => {
          return (
            <OrderCardType
              key={option}
              type={orderType.get(option)}
              hasHelp={hasHelp(option)}
              withDescription={withDescription}
              handleClick={() => handleOrderOptionSelect(orderType.get(option))}
            />
          );
        })}
    </OrderContainer>
  );
};

const OrderSearch = ({ features, handleOrderOptionSelect, withDescription, hasHelp, inputText, intl, hidden }) => {
  const results = features.filter(feature => {
    if (inputText !== '') {
      const normalizeText = text =>
        text
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase();
      const normalizedInput = normalizeText(inputText);

      const name = normalizeText(intl.formatMessage({ id: orderType.get(feature) }));
      const description = normalizeText(intl.formatMessage({ id: orderType.get(`description.${feature}`) }));
      const inputWords = normalizedInput.split(' ');

      if (
        feature.indexOf('VISTORIA') !== -1 &&
        inputWords.every(word => name.includes(word) || description.includes(word))
      ) {
        return feature;
      }
    }
    return false;
  });
  return (
    <div className={classes.orderCardType} style={{ display: hidden ? 'none' : '' }}>
      <div>
        {results.length ? (
          <h3 className={classes.orderOptionListSubtitle}>
            <FormattedMessage id="orderOptionList.allTypes" />
          </h3>
        ) : null}
      </div>
      <div className={classes.container}>
        {results.length ? (
          results.map(option => {
            return (
              <OrderCardType
                key={option}
                type={orderType.get(option)}
                hasHelp={hasHelp(option)}
                withDescription={withDescription}
                handleClick={() => handleOrderOptionSelect(orderType.get(option))}
              />
            );
          })
        ) : (
          <div style={{ marginBottom: '40px' }}>
            <FormattedMessage id="orderOptionList.noResults" />
          </div>
        )}
      </div>
    </div>
  );
};

const OrderOptionsList = ({ features, handleOrderOptionSelect, withDescription }) => {
  const [inputActive, setInputActive] = useState(false);
  const [inputText, setInputText] = useState('');
  const [hasPermissionIzee, setHasPermissionIzee] = useState(false);
  const [isLoadingIzee, setIsLoadingIzee] = useState(false);
  const intl = useIntl();
  const me = JSON.parse(sessionStorage.getItem('me'));

  const hasHelp = option => {
    const includeHelp = ['VISTORIA_DOCUSIGN'];

    return includeHelp.includes(option);
  };

  const handleRedirectToIzee = () => {
    const accessToken = sessionStorage.getItem('accessToken');
    const tokenType = sessionStorage.getItem('tokenType');
    window.open(
      `${config.APP_REDE_IZEE_URL}/#access_token=${accessToken}&token_type=${tokenType}&expires_in=`,
      '_blank'
    );
  };

  const debounceSave = useCallback(
    debounce(value => setInputText(value), 300),
    []
  );

  const handleSearchInput = event => {
    const { value } = event.target;
    debounceSave(value);
  };

  useEffect(() => {
    const cachedPermission = sessionStorage.getItem('hasPermissionIzee');
    if (cachedPermission !== null) {
      setHasPermissionIzee(JSON.parse(cachedPermission));
      return;
    }

    setIsLoadingIzee(true);
    postCheckUser(me.profile.email)
      .then(result => {
        if (result?.data) {
          const res = result.data;
          if (res?.data?.exists) {
            setHasPermissionIzee(true);
            sessionStorage.setItem('hasPermissionIzee', true);
          } else {
            sessionStorage.setItem('hasPermissionIzee', false);
          }
        }
      })
      .catch(error => {
        console.log('Error on postCheckUser:', error);
      })
      .finally(() => {
        setIsLoadingIzee(false);
      });
  }, []);

  return (
    <div className={classes.orderOptionsList}>
      <div className={classes.containerHead}>
        <div className={classes.title}>
          <Add style={{ fontSize: 30 }} />
          <h1>
            <FormattedMessage id="OrderOptionsList.newOrder" />
          </h1>
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-5 text-left" style={{ position: 'relative' }}>
            <Search className={classes.iconSearch} style={{ display: inputActive ? 'none' : '' }} />
            <input
              type="text"
              name="search"
              className={`form-control ${classes.inputSearch}`}
              placeholder={inputActive ? '' : intl.formatMessage({ id: 'orderOptionList.searchPlaceholder' })}
              style={{
                paddingLeft: inputActive ? '10px' : '40px',
                color: inputActive ? 'black' : 'gray',
                backgroundColor: inputActive ? 'white' : '',
              }}
              defaultValue={inputText}
              onChange={handleSearchInput}
              onFocus={() => {
                setInputActive(true);
              }}
              onBlur={() => {
                if (!inputText) {
                  setInputActive(false);
                }
              }}
            />
          </div>
        </div>
      </div>

      {features.filter(feature => feature === 'VISTORIA_ENTRADA' || feature === 'VISTORIA_SAIDA').length > 0 && (
        <OrderMainRequests
          features={features}
          handleOrderOptionSelect={handleOrderOptionSelect}
          withDescription={withDescription}
          hidden={!!inputText}
        />
      )}

      <OrderSearch
        features={features}
        handleOrderOptionSelect={handleOrderOptionSelect}
        withDescription={withDescription}
        hidden={!inputText}
        inputText={inputText}
        intl={intl}
        hasHelp={hasHelp}
      />

      {!!withDescription && (
        <div className={classes.orderCardService}>
          <div>
            <h3 className={classes.orderOptionListSubtitle}>
              <FormattedMessage id="orderOptionList.additionalServices" />
            </h3>
          </div>
          <div className={classes.container}>
            <OrderCardService
              type={orderType.get('VISTORIA_TOUR_VIRTUAL')}
              hasPermission={!!features.filter(feature => feature === 'VISTORIA_TOUR_VIRTUAL').length}
              hasNews={true}
              isLoading={false}
              handleClick={handleOrderOptionSelect}
            />
            <OrderCardService
              type={orderType.get('IZEE')}
              hasPermission={hasPermissionIzee}
              hasNews={false}
              isLoading={isLoadingIzee}
              handleClick={handleRedirectToIzee}
            />
            <OrderCardService
              type={orderType.get('VISTORIA_FICHA_CERTA')}
              hasPermission={!!features.filter(feature => feature === 'VISTORIA_FICHA_CERTA').length}
              hasNews={false}
              isLoading={false}
              handleClick={handleOrderOptionSelect}
            />
            <OrderCardService
              type={orderType.get('VISTORIA_DOCUSIGN')}
              hasPermission={!!features.filter(feature => feature === 'VISTORIA_DOCUSIGN').length}
              hasNews={false}
              isLoading={false}
              handleClick={handleOrderOptionSelect}
            />
          </div>
        </div>
      )}

      <OrderAllTypes
        features={features}
        handleOrderOptionSelect={handleOrderOptionSelect}
        withDescription={withDescription}
        hidden={!!inputText}
        hasHelp={hasHelp}
      />
    </div>
  );
};

OrderOptionsList.propTypes = {
  /**
   * Function to hanlde the option selection
   */
  handleOrderOptionSelect: PropTypes.func.isRequired,
  /**
   * Features of the current user
   */
  features: PropTypes.array.isRequired,
  /**
   * Boolean to know if the button have the order type description
   */
  withDescription: PropTypes.bool.isRequired,
};

export default OrderOptionsList;
