import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import DateInfoComponent from './DateInfoComponent';

const DateInfoContainer = () => {
  const { date } = useSelector(({ schedule }) => ({
    date: schedule.date,
  }));

  const intl = useIntl();

  const format = 'YYYYMMDD';
  const selectedDate = date.format(format);
  const today = moment().format(format);
  const yesterday = moment().add(-1, 'day').format(format);
  const tomorrow = moment().add(1, 'day').format(format);
  const afterTomorrow = moment().add(2, 'days').format(format);
  const beforeYesterday = moment().add(-2, 'days').format(format);

  const diffDateDays = moment(date.format(format)).diff(moment().format(format), 'days');
  const diffDateMonths = moment(date.format(format)).diff(moment().format(format), 'months');
  const diffDateYears = moment(date.format(format)).diff(moment().format(format), 'years');

  let label = '';

  if (today === selectedDate) {
    label = intl.formatMessage({ id: 'TODAY' });
  } else if (yesterday === selectedDate) {
    label = intl.formatMessage({ id: 'YESTERDAY' });
  } else if (tomorrow === selectedDate) {
    label = intl.formatMessage({ id: 'TOMORROW' });
  } else if (afterTomorrow === selectedDate) {
    label = intl.formatMessage({ id: 'AFTER_TOMORROW' });
  } else if (beforeYesterday === selectedDate) {
    label = intl.formatMessage({ id: 'BEFORE_YESTERDAY' });
  } else if (diffDateDays > 2 && diffDateDays <= 30) {
    label = intl.formatMessage({ id: 'IN_DAYS' }, { days: diffDateDays });
  } else if (diffDateDays < -2 && diffDateDays >= -30) {
    label = intl.formatMessage({ id: 'DAYS_AGO' }, { days: diffDateDays*-1 });
  } else if (diffDateMonths === 1) {
    label = intl.formatMessage({ id: 'IN_MONTH' });
  } else if (diffDateMonths > 1 && diffDateMonths <= 11) {
    label = intl.formatMessage({ id: 'IN_MONTHS' }, { months: diffDateMonths });
  } else if (diffDateMonths === -1) {
    label = intl.formatMessage({ id: 'MONTH_AGO' });
  } else if (diffDateMonths < -1 && diffDateMonths >= -11) {
    label = intl.formatMessage({ id: 'MONTHS_AGO' }, { months: diffDateMonths*-1 });
  } else if (diffDateYears === 1) {
    label = intl.formatMessage({ id: 'IN_YEAR' });
  } else if (diffDateYears > 1) {
    label = intl.formatMessage({ id: 'IN_YEARS' }, { years: diffDateYears });
  } else if (diffDateYears === -1) {
    label = intl.formatMessage({ id: 'YEAR_AGO' });
  } else if (diffDateYears < -1) {
    label = intl.formatMessage({ id: 'YEARS_AGO' }, { years: diffDateYears*-1 });
  }

  return <DateInfoComponent date={date} label={label} />;
};

export default DateInfoContainer;
