import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import PropTypes from 'prop-types';

import classes from 'src/components/OrderCardService/OrderCardService.module.scss';

import OrderCardServiceModal from '../OrderCardServiceModal/OrderCardServiceModal';
import OrderIcon from '../OrderIcon/OrderIcon';

const OrderImage = type => {
  switch (type) {
    case 'tourVirtual':
      return 'https://s.vistori.as/assets/img-service-tour-virtual.png';
    case 'izee':
      return 'https://s.vistori.as/assets/img-service-izee.png';
    case 'marketplace_fichacerta':
      return 'https://s.vistori.as/assets/img-service-fc-analise.png';
    case 'marketplace_docusign':
      return 'https://s.vistori.as/assets/img-service-docusign.png';
    default:
      return '';
  }
};

const OrderLink = type => {
  switch (type) {
    case 'tourVirtual':
      return 'https://conteudo.somosgruporv.com.br/tour-virtual-rede-vistorias-banib';
    case 'izee':
      return 'https://www.somosgruporv.com.br/jornada-de-locacao/';
    case 'marketplace_fichacerta':
      return <OrderIcon orderType={type} />;
    case 'marketplace_docusign':
      return <OrderIcon orderType={type} />;
    default:
      return '';
  }
};

const OrderButtonSubscribeNow = ({ type, customClass, onClick }) => {
  switch (type) {
    case 'tourVirtual':
      return (
        <a
          href="https://conteudo.somosgruporv.com.br/tour-virtual-rede-vistorias-banib"
          target="_blank"
          className={customClass}
        >
          <FormattedMessage id="orderCardService.subscribeNow" />
        </a>
      );
    case 'izee':
      return (
        <a href="https://www.somosgruporv.com.br/jornada-de-locacao/" target="_blank" className={customClass}>
          <FormattedMessage id="orderCardService.subscribeNow" />
        </a>
      );
    case 'marketplace_fichacerta':
      return (
        <button className={customClass} onClick={onClick}>
          <FormattedMessage id="orderCardService.activateIntegration" />
        </button>
      );
    case 'marketplace_docusign':
      return (
        <button className={customClass} onClick={onClick}>
          <FormattedMessage id="orderCardService.activateIntegration" />
        </button>
      );
    default:
      return '';
  }
};

const OrderButtonLoading = () => {
  return (
    <button className={classes.buttonLoading}>
      <FormattedMessage id="orderCardService.loading" />
    </button>
  );
};

const OrderCardService = ({ type, hasPermission, hasNews, isLoading, handleClick }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = open => {
    setOpenModal(open);
  };

  return (
    <div className={classes.orderCardService}>
      <div
        className={classes.banner}
        style={{
          backgroundImage: `url('${OrderImage(type)}')`,
        }}
      >
        {hasNews && (
          <div className={classes.news}>
            <div className={classes.text}>
              <img src="https://s.vistori.as/assets/img-icon-sparkles.png" className={classes.icon} />
              <FormattedMessage id="orderCardService.news" />
            </div>
          </div>
        )}
      </div>
      <div className={classes.container}>
        <div className={classes.containerTitle}>
          <div className={classes.icon}>
            {type === 'izee' ? (
              <img src="https://s.vistori.as/assets/img-icon-izee.png" className={classes.izee} alt="Izee" />
            ) : type === 'marketplace_docusign' ? (
              <img
                src="https://s.vistori.as/assets/img-icon-docusign.png"
                className={classes.docusign}
                alt="Docusign"
              />
            ) : (
              <OrderIcon orderType={type} />
            )}
          </div>
          <div className={classes.title}>
            <FormattedMessage id={type} />
          </div>
        </div>
        <div className={classes.description}>
          <FormattedMessage id={`description.service.${type}`} />
        </div>
        <div className={classes.containerLink}>
          {type === 'marketplace_docusign' || type === 'marketplace_fichacerta' ? (
            <span className={classes.link} onClick={() => handleOpenModal(true)}>
              <FormattedMessage id="orderCardService.moreInformation" />
            </span>
          ) : (
            <a href={OrderLink(type)} className={classes.link} target="_blank">
              <FormattedMessage id="orderCardService.moreInformation" />
            </a>
          )}
        </div>
        <div className={classes.containerButton}>
          {isLoading ? (
            <OrderButtonLoading />
          ) : hasPermission ? (
            <button className={classes.buttonBlue} onClick={() => handleClick(type)}>
              <FormattedMessage id={type === 'izee' ? 'orderCardService.accessIzee' : 'orderCardService.newOrder'} />
            </button>
          ) : (
            <OrderButtonSubscribeNow
              type={type}
              customClass={classes.buttonYellow}
              onClick={() => handleOpenModal(true)}
            />
          )}
          {type === 'marketplace_docusign' || type === 'marketplace_fichacerta' ? (
            <OrderCardServiceModal type={type} isOpen={openModal} handleClose={() => handleOpenModal(false)} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

OrderCardService.propTypes = {
  /**
   * String with namme of the displayed option
   */
  type: PropTypes.string.isRequired,
  /**
   * Boolean to know if can to access the service
   */
  hasPermission: PropTypes.bool.isRequired,
  /**
   * Boolean to know if display the link to the faq
   */
  hasNews: PropTypes.bool.isRequired,
  /**
   * Boolean to know if loading
   */
  isLoading: PropTypes.bool.isRequired,
  /**
   * Function that directs the user to the option page
   */
  handleClick: PropTypes.func.isRequired,
};

export default OrderCardService;
