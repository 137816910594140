/* eslint-disable quotes */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import update from 'immutability-helper';
import PropTypes from 'prop-types';

import ActionsMenu from 'src/ActionsMenu';
import API from 'src/apiRequest';
import AddPerformingInspector from 'src/components/AddPerformingInspector';
import AssignInspector from 'src/components/AssignInspector';
import ChecklistForm from 'src/components/ExtraActions/ChecklistForm';
import ExtraActions from 'src/components/ExtraActions/ExtraActions';
import Modal from 'src/components/common/Modal';
import RepairBudgetForm from 'src/components/ExtraActions/RepairBudgetForm';
import OrderCancelPage from 'src/components/OrderCancelPage';
import OrderIcon from 'src/components/OrderIcon/OrderIcon';
import OrderOptionsList from 'src/components/OrderOptionList/OrderOptionsList';
import OrdersReportsModal from 'src/components/OrdersReportsModal';
import { ITEMS_PER_PAGE } from 'src/constants';
import ApiContext from 'src/contexts/ApiContext';
import { AsyncServiceContext } from 'src/contexts/AsyncServiceContext';
import Empty from 'src/Empty';
import ErrorPage from 'src/ErrorPage';
import Header from 'src/Header';
import getDashboardLink from 'src/helpers/get-dashboard-link';
import getDivergencesPanelLink from 'src/helpers/get-divergences-panel-link';
import InspectionAttach from 'src/inspectionAttach/InspectionAttach';
import LeftDrawer from 'src/leftDrawer/LeftDrawer';
import Loading from 'src/Loading';
import OrderDetails from 'src/orderDetails/OrderDetails';
import EditOrderFormPage from 'src/orderForm/OrderForm/OrderFormPage/EditOrderFormPage';
import OrderFormPage from 'src/orderForm/OrderForm/OrderFormPage/OrderFormPage';
import OrderSelectionMultiple from 'src/OrderSelectionMultiple';
import OrdersList from 'src/OrdersList';
import { setQuery } from 'src/store/ducks/filter';

const styles = {
  mainPage: {
    backgroundColor: '#EAEAEA',
    paddingTop: 54,
    paddingLeft: 220,
    position: 'fixed',
    height: '100%',
  },
  actionsMenu: {
    paddingBottom: '1vw',
  },
  scroll: {
    maxHeight: 'calc(100% - (58px))',
    height: 'calc(100% - (58px))',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingBottom: 20,
  },
};

const newElement = {
  id: null,
  identifier: 'identifier',
  type: 'type',
  code: 'Code',
  status: 'WAITING',
  credits: null,
  express: null,
  premium: null,
  building_type: 'building_type',
  created_at: new Date(),
  owner: 'owner',
  client: 'client',
};

const status = new Map([
  // Active
  ['waiting', 'waiting'],
  ['accepted', 'accepted'],
  ['assigned', 'assigned'],
  ['in_progress', 'in_progress'],
  ['finalized', 'finalized'],
  ['archived', 'archived'],
  ['vnr', 'vnr'],
  // Finished
  ['sent', 'sent'],
  ['rejected', 'rejected'],
  ['canceled', 'canceled'],
  // Inspections
  ['independent', 'independent'],
]);

const currentPageByCurrentMenu = new Map([
  // Default
  ['active', 'active'],
  // Active
  ['waiting', 'active'],
  ['accepted', 'active'],
  ['assigned', 'active'],
  ['in_progress', 'active'],
  ['finalized', 'active'],
  ['vnr', 'active'],
  // Archived
  ['archived', 'archived'],
  // Finished
  ['sent', 'sent'],
  ['rejected', 'rejected'],
  ['canceled', 'canceled'],
  // Inspections
  ['independent', 'independent'],
]);

const orderStatusToStatusLabel = new Map([
  // Active
  ['WAITING', 'waiting'],
  ['ACCEPTED', 'accepted'],
  ['ASSIGNED', 'assigned'],
  ['IN_PROGRESS', 'in_progress'],
  ['FINALIZED_BY_DEVICE', 'finalized'],
  ['VNR', 'vnr'],
  // Archived
  ['ARCHIVED', 'archived'],
  // Finished
  ['FINISHED', 'sent'],
  ['REJECTED', 'rejected'],
  ['CANCELED', 'canceled'],
  // Inspections
  ['FINALIZED', 'independent'],
]);

const currentPageByOrderStatus = new Map([
  // Active
  ['WAITING', 'active'],
  ['ACCEPTED', 'active'],
  ['ASSIGNED', 'active'],
  ['IN_PROGRESS', 'active'],
  ['FINALIZED_BY_DEVICE', 'active'],
  ['VNR', 'active'],
  // Archived
  ['ARCHIVED', 'archived'],
  // Finished
  ['FINISHED', 'sent'],
  ['REJECTED', 'rejected'],
  ['CANCELED', 'canceled'],
  // Inspections
  ['FINALIZED', 'independent'],
]);

const statusLabelToSubMenu = new Map([
  // Active
  ['waiting', 'active'],
  ['accepted', 'active'],
  ['assigned', 'active'],
  ['in_progress', 'active'],
  ['finalized', 'active'],
  ['archived', 'active'],
  ['vnr', 'active'],
  // Finished
  ['sent', 'finished'],
  ['rejected', 'finished'],
  ['canceled', 'finished'],
  // Inspections
  ['independent', 'independent'],
]);

let source = null;

class FranchiseeApp extends Component {
  static contextType = AsyncServiceContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      orderBy: 'created_at',
      orderByDir: 'desc',
      rightState: 'empty',
      loadingOrders: false,
      activeOrderItemId: null, // ID of the active order item
      activeOrderStatus: null,
      newOrderType: null, // Type of the new order
      previousOrderId: null, // Previous order ID for the association
      selectedCheckboxes: new Set(),
      actionsMenuChecked: false,
      previousOrderFile: null,
      data: [],
      activeSubMenu: 'active',
      activeSubMenuIndex: 0,
      activeCollapseSubMenu: null,
      headerSearchValue: '',
      hasMoreItems: true,
      apiStatus: 'IDLE',
      orderIdFromUrl: null,
      isDisplayingSearchResults: false,
      modals: { extract: false },
      menu: [
        {
          key: 'extract',
          active: false,
          requiredFeature: 'ordersReports',
          onClick: () => this.setState(({ modals }) => ({ modals: { ...modals, extract: !modals.extract }})),
        },
        {
          key: 'schedule',
          active: false,
          routeLink: '/schedule',
          requiredFeature: 'schedule',
          subMenu: [],
        },
        {
          key: 'clients',
          active: false,
          routeLink: '/clients',
          requiredFeature: 'client',
          subMenu: [
            {
              key: 'actives',
              active: true,
              content: 0,
            },
            {
              key: 'deleted',
              active: false,
              content: 0,
            },
          ],
        },
        {
          key: 'divergences',
          active: false,
          routeLink: '/divergences',
          requiredFeature: 'divergencePanel',
          onClick: getDivergencesPanelLink,
        },
        {
          key: 'dashboard',
          active: false,
          routeLink: '/dashboard',
          onClick: getDashboardLink,
        },
        {
          key: 'inspectors',
          active: false,
          routeLink: '/inspectors',
          requiredFeature: 'inspector',
          subMenu: [
            {
              key: 'active',
              active: true,
              content: 0,
            },
            {
              key: 'deleted',
              active: false,
              content: 0,
            },
          ],
        },
        {
          key: 'settings',
          active: false,
          routeLink: '/settings',
          requiredFeature: 'user',
          subMenu: [],
        },
        {
          key: 'inspections',
          active: true,
          subMenu: [
            {
              key: 'active',
              active: true,
              disabled: false,
              content: 0,
              routeLink: null,
              collapseSubMenu: [
                {
                  key: 'waiting',
                  active: true,
                  disabled: false,
                  content: 0,
                  routeLink: '/orders?tab=waiting',
                },
                {
                  key: 'accepted',
                  active: false,
                  disabled: false,
                  content: 0,
                  routeLink: '/orders?tab=accepted',
                },
                {
                  key: 'assigned',
                  active: false,
                  disabled: false,
                  content: 0,
                  routeLink: '/orders?tab=assigned',
                },
                {
                  key: 'in_progress',
                  active: false,
                  disabled: false,
                  content: 0,
                  routeLink: '/orders?tab=in_progress',
                },
                {
                  key: 'finalized',
                  active: false,
                  disabled: false,
                  content: 0,
                  routeLink: '/orders?tab=finalized',
                },
                {
                  key: 'archived',
                  active: false,
                  disabled: false,
                  content: 0,
                  routeLink: '/orders?tab=archived',
                },
                {
                  key: 'vnr',
                  active: false,
                  disabled: false,
                  content: 0,
                  routeLink: '/orders?tab=vnr',
                },
              ],
            },
            {
              key: 'finished',
              active: false,
              disabled: false,
              content: 0,
              routeLink: null,
              collapseSubMenu: [
                {
                  key: 'sent',
                  active: false,
                  disabled: false,
                  content: 0,
                  routeLink: '/orders?tab=sent',
                },
                {
                  key: 'rejected',
                  active: false,
                  disabled: false,
                  content: 0,
                  routeLink: '/orders?tab=rejected',
                },
                {
                  key: 'canceled',
                  active: false,
                  disabled: false,
                  content: 0,
                  routeLink: '/orders?tab=canceled',
                },
              ],
            },
            {
              key: 'independent',
              active: false,
              disabled: false,
              content: 0,
              routeLink: '/inspections',
            },
          ],
        },
      ],
    };
    this.timer = null;
  }

  async componentDidMount() {
    const { menu, orderBy } = this.state;
    const { location, history, setQuery } = this.props; // eslint-disable-line no-shadow

    setQuery({});

    this.setState({ loadingOrders: true });

    try {
      const url = String(location.pathname);

      const activeMenuIndex = menu.findIndex(item => !!item.active);
      const activeSubMenuIndex = menu[activeMenuIndex].subMenu.findIndex(subMenu => !!subMenu.active);
      const activeCollapseSubMenuIndex = menu[activeMenuIndex].subMenu[activeSubMenuIndex].collapseSubMenu.findIndex(
        collapseSubMenu => !!collapseSubMenu.active
      );

      if (url.length > 26 && url.includes('/orders/') && !url.includes('/tab') && !url.includes('/inspections')) {
        const orderId = url.substr(url.lastIndexOf('/') + 1, url.length);

        if (!orderId || !/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(orderId)) {
          throw new Error('orderId param is required and must be a valid UUID');
        }
        const { data: orderById } = await API.get(`/view/orders/${orderId}`);

        let labelKey = 'archived';
        let updatedMenu = null;
        let subMenuByStatus = null;

        if (!orderById.is_archived) {
          orderStatusToStatusLabel.forEach((statusLabel, stts) => {
            if (stts === orderById.status) {
              labelKey = statusLabel;
            }
          });
        }

        const { data: ordersByStatus } = await API.get(`/orders?status=${labelKey}&limit=${ITEMS_PER_PAGE}`);

        statusLabelToSubMenu.forEach((subMenu, statusLabel) => {
          if (statusLabel === labelKey) {
            subMenuByStatus = subMenu;
          }
        });

        const newActiveSubMenu = menu[activeMenuIndex].subMenu.find(subMenu => subMenu.key === subMenuByStatus);
        const newActiveSubMenuIndex = menu[activeMenuIndex].subMenu.findIndex(subMenu => {
          return subMenu.key === subMenuByStatus;
        });

        let activeCollapseSubMenu = null;

        if (newActiveSubMenu && newActiveSubMenu.collapseSubMenu) {
          const newActiveCollapseSubMenuIndex = newActiveSubMenu.collapseSubMenu.findIndex(collpaseSubMenu => {
            return collpaseSubMenu.key === labelKey;
          });

          if (newActiveCollapseSubMenuIndex !== -1) {
            activeCollapseSubMenu = newActiveSubMenu.collapseSubMenu[newActiveCollapseSubMenuIndex];

            if (newActiveSubMenuIndex === activeSubMenuIndex) {
              updatedMenu = update(menu, {
                [activeMenuIndex]: {
                  subMenu: {
                    [activeSubMenuIndex]: {
                      collapseSubMenu: {
                        [activeCollapseSubMenuIndex]: {
                          active: { $set: false },
                        },
                        [newActiveCollapseSubMenuIndex]: {
                          active: { $set: true },
                        },
                      },
                    },
                  },
                },
              });
            } else {
              updatedMenu = update(menu, {
                [activeMenuIndex]: {
                  subMenu: {
                    [activeSubMenuIndex]: {
                      active: { $set: false },
                      collapseSubMenu: {
                        [activeCollapseSubMenuIndex]: {
                          active: { $set: false },
                        },
                      },
                    },
                    [newActiveSubMenuIndex]: {
                      active: { $set: true },
                      collapseSubMenu: {
                        [newActiveCollapseSubMenuIndex]: {
                          active: { $set: true },
                        },
                      },
                    },
                  },
                },
              });
            }
          }
        }

        this.setState(state => ({
          data: update(state.data, { $push: ordersByStatus }),
          loadingOrders: false,
          menu: updatedMenu,
          activeSubMenu: newActiveSubMenu.key,
          activeSubMenuIndex: newActiveSubMenuIndex,
          activeCollapseSubMenu: activeCollapseSubMenu.key ?? null,
        }));
      } else if (url.includes('/inspections')) {
        this.setDefaltOrderBy('independent');
        const inspections = await API.get(
          `/inspections?status=independent&limit=${ITEMS_PER_PAGE}&order_by=${orderBy}&order_by_dir=desc`
        );

        const newActiveSubMenuIndex = menu[activeMenuIndex].subMenu.findIndex(subMenu => subMenu.key === 'independent');

        const updatedMenu = update(menu, {
          [activeMenuIndex]: {
            subMenu: {
              [activeSubMenuIndex]: {
                active: { $set: false },
                collapseSubMenu: {
                  [activeCollapseSubMenuIndex]: {
                    active: { $set: false },
                  },
                },
              },
              [newActiveSubMenuIndex]: {
                active: { $set: true },
              },
            },
          },
        });

        this.setState(state => ({
          data: update(state.data, { $push: inspections.data }),
          loadingOrders: false,
          menu: updatedMenu,
          activeSubMenu: 'independent',
          activeSubMenuIndex: 2,
        }));
      } else {
        this.setDefaltOrderBy('waiting');
        const activeOrders = await API.get(
          `/orders?status=waiting&limit=${ITEMS_PER_PAGE}&order_by=${orderBy}&order_by_dir=asc`
        );

        this.setState(state => ({
          data: update(state.data, { $push: activeOrders.data }),
          loadingOrders: false,
          activeSubMenu: 'waiting',
        }));
      }
    } catch (err) {
      console.debug('FranchiseeApp.componentDidMount error', err);

      this.setState({ loadingOrders: false });

      history.push('/orders');
    }
  }

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  mergeInspectionHandler = () => {
    const { activeOrderItemId, selectedCheckboxes } = this.state;
    let selectedOrders = [];

    if (selectedCheckboxes.size > 0) {
      selectedOrders = Array.from(selectedCheckboxes);
    } else if (activeOrderItemId !== null) {
      selectedOrders = [activeOrderItemId];
    } else {
      return;
    }
    alert(selectedOrders);
  };

  callActionHandler = (e, action, orderId = null) => {
    const { history } = this.props;
    const { activeOrderItemId, activeSubMenu } = this.state;

    orderId = activeOrderItemId || orderId;

    switch (action) {
      case 'export':
        this.actionsMenuGeneratePhotos(e);
        break;
      case 'report':
        this.actionsMenuGeneratePDF(e);
        break;
      case 'edit':
        this.actionsMenuEditOrder(e);
        break;
      case 'delete':
        this.actionsMenuOrderDelete(e);
        break;
      case 'cancel':
        this.actionsMenuOrderCancel(e);
        break;
      case 'archive':
        this.actionsMenuOrderArchive(e, 'archive');
        break;
      case 'unarchive':
        this.actionsMenuOrderArchive(e, 'unarchive');
        break;
      case 'merge':
        this.mergeInspectionHandler();
        break;
      case 'extra':
        this.setState({ rightState: 'extraActions' });
        history.push('/orders/extra-actions');
        break;
      case 'checklist-form':
        this.setState({ rightState: 'checklistForm' });
        history.push('/orders/checklist');
        break;
      case 'repair-budget-form':
        this.setState({ rightState: 'repairBudgetForm' });
        history.push(`/orders/${orderId}/repair-budget`);
        break;
      case 'order-details': {
        this.setState({ rightState: 'orderDetails' });

        if (activeSubMenu !== 'independent') {
          history.push(`/orders/${orderId}`);
        } else {
          history.push(`/inspections/${orderId}`);
        }

        break;
      }
      default:
        console.error('Invalid action type: ', action);
        break;
    }
  };

  setDefaltOrderBy = activeSubMenu => {
    switch (activeSubMenu) {
      case 'sent':
        this.setState({ orderByDir: 'desc' });
        break;
      case 'rejected':
        this.setState({ orderByDir: 'desc' });
        break;
      case 'canceled':
        this.setState({ orderByDir: 'desc' });
        break;
      case 'independent':
        this.setState({ orderByDir: 'desc' });
        break;
      default:
        this.setState({ orderByDir: 'asc' });
        break;
    }
  };

  changeOrderByDir = async e => {
    await this.setState({ orderByDir: e.value });
    const { activeSubMenu, orderBy, headerSearchTags, headerSearchType, headerSearchValue } = this.state;

    if (headerSearchTags || headerSearchType || headerSearchValue) {
      this.triggerSearch();
    } else if (activeSubMenu === 'independent') {
      this.setState({ loadingOrders: true });
      const inspections = await API.get(
        `/inspections?status=independent&limit=${ITEMS_PER_PAGE}&order_by=${orderBy}&order_by_dir=${e.value}`
      );
      this.setState({
        data: inspections.data,
        loadingOrders: false,
      });
    } else {
      await this.handleOrdersRequestByStatus(activeSubMenu);
    }
  };

  /*
    Function to refresh the data of the list
  */
  refreshData = () => {
    const { activeSubMenu, activeCollapseSubMenu, rightState, orderByDir, orderBy } = this.state;
    const { intl, ordersUpdated, history } = this.props;

    let refreshRoute = '/orders';

    // Check if the new order form is active to open confirmation dialog
    if (rightState === 'orderForm') {
      const confMessage = intl.formatMessage({
        id: 'confirmation.cancelNewOrder',
      });

      if (window.confirm(confMessage)) {
        this.setState(prevState => ({
          data: update(prevState.data, { $splice: [[0, 1]] }),
          rightState: 'empty',
          selectedCheckboxes: new Set(),
          previousOrderFile: null,
          previousOrderId: null,
          loadingOrders: true,
          isDisplayingSearchResults: false,
        }));
      }
    } else if (rightState === 'orderOptions' || rightState === 'orderAttach') {
      this.setState(prevState => ({
        data: update(prevState.data, { $splice: [[0, 1]] }),
        rightState: 'empty',
        selectedCheckboxes: new Set(),
        loadingOrders: true,
        isDisplayingSearchResults: false,
      }));
    } else if (rightState === 'orderEdit') {
      const confMessage = intl.formatMessage({
        id: 'confirmation.cancelEditOrder',
      });

      if (window.confirm(confMessage)) {
        this.setState({
          rightState: 'empty',
          activeOrderItemId: null,
          activeOrderStatus: null,
          selectedCheckboxes: new Set(),
          loadingOrders: true,
          isDisplayingSearchResults: false,
        });
      }
    } else {
      this.setState({
        rightState: 'empty',
        activeOrderItemId: null,
        activeOrderStatus: null,
        selectedCheckboxes: new Set(),
        loadingOrders: true,
        isDisplayingSearchResults: false,
      });
    }

    let orderStatus = status.get(activeCollapseSubMenu);
    let url = `/orders?status=${orderStatus}&limit=${ITEMS_PER_PAGE}&order_by=${orderBy}&order_by_dir=${orderByDir}`;

    if (activeSubMenu === 'independent') {
      orderStatus = status.get(activeSubMenu);

      refreshRoute = '/inspections';
      url = `/inspections?status=${orderStatus}&limit=${ITEMS_PER_PAGE}&order_by=${orderBy}&order_by_dir=${orderByDir}`;
    }

    if (!orderStatus) {
      console.debug('FranchiseeApp.refreshData error', `orderStatus not found for ${activeCollapseSubMenu} or ${activeSubMenu}`);

      return;
    }

    API.get(url).then(res => {
      ordersUpdated();

      this.setState({
        data: res.data,
        loadingOrders: false,
        hasMoreItems: true,
        isDisplayingSearchResults: false,
      });

      history.push(refreshRoute);
    });
  };

  /*
      Function to decrement the badge of the active submenu
    */
  decrementBadge = () => {
    const { menu } = this.state;
    const activeMenu = menu.find(option => option.active === true);
    const activeMenuIndex = menu.indexOf(activeMenu);
    const activeSubMenu = menu[activeMenuIndex].subMenu.find(option => option.active === true);
    const activeSubMenuIndex = menu[activeMenuIndex].subMenu.indexOf(activeSubMenu);
    const { content } = menu[activeMenuIndex].subMenu[activeSubMenuIndex];

    this.setState(prevState => ({
      menu: update(prevState.menu, {
        [activeMenuIndex]: {
          subMenu: {
            [activeSubMenuIndex]: {
              content: { $set: content - 1 },
            },
          },
        },
      }),
    }));
  };

  /*
    Function to handle the search box of the header
  */
  handleHeaderSearch = (searchString, tags, type, orderStatus) => {
    const { menu, rightState } = this.state;
    const { intl, ordersUpdated } = this.props;
    let confirm = true;

    // Check if need user confirmation
    if (rightState === 'orderForm') {
      const confMessage = intl.formatMessage({ id: 'confirmation.cancelNewOrder' });
      if (!window.confirm(confMessage)) {
        confirm = false;
      }
    } else if (rightState === 'orderEdit') {
      const confMessage = intl.formatMessage({ id: 'confirmation.cancelEditOrder' });
      if (!window.confirm(confMessage)) {
        confirm = false;
      }
    }

    if (confirm) {
      ordersUpdated();

      const activeMenuIndex = menu.findIndex(menuOption => menuOption.active === true);

      const updateMenuOptions = {
        subMenu: {
          0: {
            active: { $set: false },
            collapseSubMenu: {
              0: { active: { $set: false } },
              1: { active: { $set: false } },
              2: { active: { $set: false } },
              3: { active: { $set: false } },
              4: { active: { $set: false } },
              5: { active: { $set: false } },
              6: { active: { $set: false } },
            },
          },
          1: {
            active: { $set: false },
            collapseSubMenu: {
              0: { active: { $set: false } },
              1: { active: { $set: false } },
              2: { active: { $set: false } },
            },
          },
          2: {
            active: { $set: false },
          },
        },
      };

      this.setState({
        headerSearchValue: searchString,
        headerSearchTags: tags,
        headerSearchType: type,
        headerSearchOrderStatus: orderStatus,
        menu: update(menu, { [activeMenuIndex]: updateMenuOptions }),
      });

      clearTimeout(this.timer);

      this.timer = setTimeout(this.triggerSearch, 500);
    }
  };

  /*
   * Function to chage the active menu item
   */
  handleMenuChange = (index, e, link, routeLink) => {
    const { history } = this.props;

    if (routeLink !== undefined) {
      history.push(routeLink);
      return;
    }

    if (link !== undefined) {
      window.location = `${window.location.protocol}//${window.location.hostname}:${window.location.port}${link}`;
      return;
    }
  };

  /*
   * Function to chage the active sub-menu item, by default the first submenu item is active
   */
  handleSubMenuChange = async (newSubMenuIndex, e, routeLink) => {
    const { menu, rightState, orderBy, orderByDir } = this.state;
    const { intl, ordersUpdated, history } = this.props;

    const activeMenuIndex = menu.findIndex(option => option.active === true);
    const activeSubMenuIndex = menu[activeMenuIndex].subMenu.findIndex(option => option.active === true);
    const newActiveSubMenu = menu[activeMenuIndex].subMenu[newSubMenuIndex].key;

    await this.setDefaltOrderBy(newActiveSubMenu);

    let confirm = true;

    if (rightState === 'orderForm') {
      const confMessage = intl.formatMessage({ id: 'confirmation.cancelNewOrder' });

      if (!window.confirm(confMessage)) confirm = false;
    } else if (rightState === 'orderEdit') {
      const confMessage = intl.formatMessage({ id: 'confirmation.cancelEditOrder' });

      if (!window.confirm(confMessage)) confirm = false;
    }

    const orderStatus = status.get(newActiveSubMenu);

    if (!orderStatus) {
      console.debug('FranchiseeApp.handleSubMenuChange error', `orderStatus not found for ${newActiveSubMenu}`);

      return;
    }

    let url = `/orders?status=${orderStatus}&limit=${ITEMS_PER_PAGE}&order_by=${orderBy}&order_by_dir=${orderByDir}`;

    if (newActiveSubMenu === 'independent') {
      url = `/inspections?status=${orderStatus}&limit=${ITEMS_PER_PAGE}&order_by=${orderBy}&order_by_dir=${orderByDir}`;
    }

    if (activeSubMenuIndex !== -1) {
      const activeSubMenuUpdateOptions = {
        [activeSubMenuIndex]: {
          active: { $set: false },
        },
      };

      if (Array.isArray(menu[activeMenuIndex].subMenu[activeSubMenuIndex].collapseSubMenu)) {
        const collapseSubMenuIndex = menu[activeMenuIndex].subMenu[activeSubMenuIndex].collapseSubMenu.findIndex(
          collapseSubMenuOption => collapseSubMenuOption.active === true
        );

        if (collapseSubMenuIndex !== -1) {
          activeSubMenuUpdateOptions[activeSubMenuIndex].collapseSubMenu = {
            [collapseSubMenuIndex]: {
              active: { $set: false },
            },
          };
        }
      }

      const newMenuOptions = update(menu, {
        [activeMenuIndex]: {
          subMenu: {
            ...activeSubMenuUpdateOptions,
            [newSubMenuIndex]: {
              active: { $set: true },
            },
          },
        },
      });

      if (confirm) {
        ordersUpdated();

        this.setState({
          loadingOrders: true,
          rightState: 'empty',
          activeOrderItemId: null,
          activeOrderStatus: null,
          newOrderType: null,
          previousOrderId: null,
          selectedCheckboxes: new Set(),
          actionsMenuChecked: false,
          hasMoreItems: true,
          previousOrderFile: null,
          menu: newMenuOptions,
          activeSubMenu: newActiveSubMenu,
          headerSearchValue: null,
          headerSearchTags: null,
          headerSearchType: null,
          isDisplayingSearchResults: false,
        });
      }
    } else if (confirm) {
      const newMenuOptions = update(menu, {
        [activeMenuIndex]: {
          subMenu: {
            [newSubMenuIndex]: {
              active: { $set: true },
            },
          },
        },
      });

      ordersUpdated();

      this.setState({
        loadingOrders: true,
        rightState: 'empty',
        activeOrderItemId: null,
        activeOrderStatus: null,
        newOrderType: null,
        previousOrderId: null,
        selectedCheckboxes: new Set(),
        actionsMenuChecked: false,
        hasMoreItems: true,
        previousOrderFile: null,
        menu: newMenuOptions,
        activeSubMenu: newActiveSubMenu,
        headerSearchValue: null,
        headerSearchTags: null,
        headerSearchType: null,
        isDisplayingSearchResults: false,
      });
    }

    if (routeLink) {
      API.get(url).then(res => {
        this.setState({
          loadingOrders: false,
          data: res.data,
          status,
        });
      });

      history.push(routeLink);
    }
  };

  handleCollapseSubMenuChange = async (
    newSubMenuIndex = 0,
    newCollapseSubMenuIndex = 0,
    routeLink = '/orders?tab=waiting'
  ) => {
    const { menu } = this.state;
    const { history } = this.props;

    const activeMenuIndex = menu.findIndex(menuOption => menuOption.active === true);
    const activeSubMenuIndex = menu[activeMenuIndex].subMenu.findIndex(subMenuOption => subMenuOption.active === true);
    let newActiveCollapseSubMenu = null;

    if (activeMenuIndex !== -1 && activeSubMenuIndex !== -1) {
      const updateMenuOptions = {
        subMenu: {
          0: {
            active: { $set: false },
            collapseSubMenu: {
              0: { active: { $set: false } },
              1: { active: { $set: false } },
              2: { active: { $set: false } },
              3: { active: { $set: false } },
              4: { active: { $set: false } },
              5: { active: { $set: false } },
              6: { active: { $set: false } },
            },
          },
          1: {
            active: { $set: false },
            collapseSubMenu: {
              0: { active: { $set: false } },
              1: { active: { $set: false } },
              2: { active: { $set: false } },
            },
          },
          2: {
            active: { $set: false },
          },
        },
      };

      Object.keys(updateMenuOptions.subMenu).forEach(subMenuIndex => {
        if (Number(subMenuIndex) === Number(newSubMenuIndex)) {
          updateMenuOptions.subMenu[subMenuIndex].active = { $set: true };

          if (updateMenuOptions.subMenu[subMenuIndex].collapseSubMenu) {
            Object.keys(updateMenuOptions.subMenu[subMenuIndex].collapseSubMenu).forEach(collapseSubMenuIndex => {
              if (Number(collapseSubMenuIndex) === Number(newCollapseSubMenuIndex)) {
                updateMenuOptions.subMenu[subMenuIndex].collapseSubMenu[collapseSubMenuIndex].active = { $set: true };

                const newCollapseSubMenu =
                  menu[activeMenuIndex].subMenu[subMenuIndex].collapseSubMenu[collapseSubMenuIndex];

                if (newCollapseSubMenu.key) {
                  newActiveCollapseSubMenu = newCollapseSubMenu.key;
                }
              }
            });
          }
        }
      });

      const newOrderStatus = routeLink.substr(routeLink.lastIndexOf('=') + 1, routeLink.length);
      await this.setDefaltOrderBy(newOrderStatus);

      this.setState({
        menu: update(menu, { [activeMenuIndex]: updateMenuOptions }),
        activeSubMenu: newOrderStatus,
        activeCollapseSubMenu: newActiveCollapseSubMenu,
        isDisplayingSearchResults: false,
        hasMoreItems: true,
        headerSearchValue: null,
        headerSearchTags: null,
        headerSearchType: null,
      });

      history.push(routeLink);

      await this.handleOrdersRequestByStatus(newOrderStatus);
    } else {
      const updateMenuOptions = {
        subMenu: {
          [newSubMenuIndex]: {
            active: { $set: true },
            collapseSubMenu: {
              [newCollapseSubMenuIndex]: {
                active: { $set: true },
              },
            },
          },
        },
      };

      const newCollapseSubMenu =
        menu[activeMenuIndex].subMenu[newSubMenuIndex].collapseSubMenu[newCollapseSubMenuIndex];

      if (newCollapseSubMenu.key) {
        newActiveCollapseSubMenu = newCollapseSubMenu.key;
      }

      const newOrderStatus = routeLink.substr(routeLink.lastIndexOf('=') + 1, routeLink.length);
      await this.setDefaltOrderBy(newOrderStatus);

      this.setState({
        menu: update(menu, { [activeMenuIndex]: updateMenuOptions }),
        activeSubMenu: newOrderStatus,
        activeCollapseSubMenu: newActiveCollapseSubMenu,
        isDisplayingSearchResults: false,
        headerSearchValue: null,
        headerSearchTags: null,
        headerSearchType: null,
      });

      history.push(routeLink);

      await this.handleOrdersRequestByStatus(newOrderStatus);
    }
  };

  handleOrdersRequestByStatus = async (orderStatus = 'waiting') => {
    const { setQuery } = this.props; // eslint-disable-line no-shadow
    const { orderByDir, orderBy } = this.state; // eslint-disable-line no-shadow

    setQuery({});

    this.setState({ loadingOrders: true });

    try {
      if (!orderStatus) throw new Error();

      const ordersByStatus = await API.get(
        `/orders?status=${orderStatus}&limit=${ITEMS_PER_PAGE}&order_by=${orderBy}&order_by_dir=${orderByDir}`
      );

      this.setState({
        data: ordersByStatus?.data || [],
        loadingOrders: false,
        hasMoreItems: ordersByStatus?.data?.length === ITEMS_PER_PAGE,
      });
    } catch (err) {
      console.debug('FranchiseeApp.handleOrdersRequestByStatus error', err);

      this.setState({ loadingOrders: false, hasMoreItems: false });
    }
  };

  /*
    Function to handle open the details of an order
  */
  orderShowDetailsAction = orderIndex => {
    const { activeSubMenu, data, rightState } = this.state;
    const { intl, history } = this.props;
    const order = data[orderIndex];
    const orderId = order.id;
    const orderStatus = order.status;

    // Check if the new order form is active to open confirmation dialog
    if (rightState === 'orderOptions' || rightState === 'orderAttach') {
      this.setState(prevState => ({
        data: update(prevState.data, { $splice: [[0, 1]] }),
        rightState: 'orderDetails',
        activeOrderItemId: orderId,
        activeOrderStatus: orderStatus,
        orderIdFromUrl: null,
      }));
    } else if (rightState === 'orderForm') {
      const confMessage = intl.formatMessage({
        id: 'confirmation.cancelNewOrder',
      });
      if (window.confirm(confMessage)) {
        this.setState(prevState => ({
          data: update(prevState.data, { $splice: [[0, 1]] }),
          rightState: 'orderDetails',
          activeOrderItemId: orderId,
          activeOrderStatus: orderStatus,
          previousOrderId: null,
          previousOrderFile: null,
          orderIdFromUrl: null,
        }));
      }
    } else if (rightState === 'orderEdit') {
      const confMessage = intl.formatMessage({
        id: 'confirmation.cancelEditOrder',
      });
      if (window.confirm(confMessage)) {
        this.setState({
          rightState: 'orderDetails',
          activeOrderItemId: orderId,
          activeOrderStatus: orderStatus,
          orderIdFromUrl: null,
        });
      }
    } else {
      this.setState({
        rightState: 'orderDetails',
        activeOrderItemId: orderId,
        activeOrderStatus: orderStatus,
        selectedCheckboxes: new Set(),
        actionsMenuChecked: false,
        orderIdFromUrl: null,
      });
    }

    if (activeSubMenu !== 'independent') {
      history.push(`/orders/${orderId}`);
    } else {
      history.push(`/inspections/${orderId}`);
    }
  };

  showInspectionErrorHandler = () => {
    const { history } = this.props;

    this.setState({
      rightState: 'error',
      newOrderType: null,
      previousOrderId: null,
      previousOrderFile: null,
    });

    history.push('/orders/error');
  };

  /*
    Function to handle check/ removecheck of the list items
  */
  actionsMenuCheckAllAction = (e, action) => {
    const { data } = this.state;
    const { history } = this.props;

    let newSelectedCheckboxes;
    let isEmptyOrSelectionRoute;

    if (action === 'check') {
      newSelectedCheckboxes = new Set(data.map(order => order.id));
    } else {
      newSelectedCheckboxes = new Set();
    }

    this.setState(prevState => {
      isEmptyOrSelectionRoute = prevState.actionsMenuChecked ? 'empty' : 'orderSelection';

      if (isEmptyOrSelectionRoute === 'orderSelection') {
        history.push('/orders/selection');
      } else {
        history.push('/orders');
      }

      return {
        actionsMenuChecked: !prevState.actionsMenuChecked,
        selectedCheckboxes: newSelectedCheckboxes,
        rightState: isEmptyOrSelectionRoute,
        newOrderType: null,
        activeOrderItemId: null,
        activeOrderStatus: null,
        previousOrderFile: null,
      };
    });
  };

  /*
    Function to check/ removecheck of one list item
  */
  checkOneItemAction = (id, evt) => {
    evt.stopPropagation();
    const { rightState, selectedCheckboxes } = this.state;
    const { intl, history } = this.props;
    const newSelectedCheckboxes = new Set(selectedCheckboxes);

    if (selectedCheckboxes.has(id)) {
      newSelectedCheckboxes.delete(id);
    } else {
      newSelectedCheckboxes.add(id);
    }

    // Check if the new order form is active to open confirmation dialog
    if (rightState === 'orderForm') {
      const confMessage = intl.formatMessage({
        id: 'confirmation.cancelNewOrder',
      });
      if (window.confirm(confMessage)) {
        this.setState(prevState => ({
          data: update(prevState.data, { $splice: [[0, 1]] }),
          rightState: 'orderSelection',
          selectedCheckboxes: newSelectedCheckboxes,
          previousOrderFile: null,
          previousOrderId: null,
        }));
      }
    } else if (rightState === 'orderOptions' || rightState === 'orderAttach') {
      this.setState(prevState => ({
        data: update(prevState.data, { $splice: [[0, 1]] }),
        rightState: 'orderSelection',
        selectedCheckboxes: newSelectedCheckboxes,
      }));
    } else if (rightState === 'orderEdit') {
      const confMessage = intl.formatMessage({
        id: 'confirmation.cancelEditOrder',
      });
      if (window.confirm(confMessage)) {
        this.setState({
          rightState: 'orderSelection',
          activeOrderItemId: null,
          activeOrderStatus: null,
          selectedCheckboxes: newSelectedCheckboxes,
        });
      }
    } else {
      const isEmptyOrSelectionRoute = newSelectedCheckboxes.size > 0 ? 'orderSelection' : 'empty';

      this.setState({
        rightState: isEmptyOrSelectionRoute,
        activeOrderItemId: null,
        activeOrderStatus: null,
        selectedCheckboxes: newSelectedCheckboxes,
      });

      if (isEmptyOrSelectionRoute === 'orderSelection') {
        history.push('/orders/selection');
      } else {
        history.push('/orders');
      }

      return;
    }

    history.push('/orders/selection');
  };

  /*
    Function to handle the action buttons for a new order
  */
  actionsMenuNewOrder = () => {
    const { data, rightState } = this.state;
    const { intl, history } = this.props;
    // Check if the new order form is active to open confirmation dialog
    if (rightState === 'orderForm') {
      const confMessage = intl.formatMessage({
        id: 'confirmation.cancelNewOrder',
      });
      if (window.confirm(confMessage)) {
        this.setState({
          data: update(data, { 0: { $set: newElement } }),
          rightState: 'orderOptions',
          previousOrderFile: null,
          previousOrderId: null,
          newOrderType: null,
        });
      }
    } else if (rightState === 'orderOptions' || rightState === 'orderAttach') {
      this.setState({
        data: update(data, { 0: { $set: newElement } }),
        rightState: 'orderOptions',
        newOrderType: null,
      });
    } else if (rightState === 'orderEdit') {
      const confMessage = intl.formatMessage({
        id: 'confirmation.cancelEditOrder',
      });
      if (window.confirm(confMessage)) {
        this.setState({
          data: update(data, { $unshift: [newElement] }),
          rightState: 'orderOptions',
          activeOrderItemId: null,
          activeOrderStatus: null,
        });
      }
    } else {
      this.setState({
        data: update(data, { $unshift: [newElement] }),
        actionsMenuChecked: false,
        activeOrderItemId: null,
        activeOrderStatus: null,
        rightState: 'orderOptions',
        selectedCheckboxes: new Set(),
      });
    }

    history.push('/orders/new');
  };

  /*
    Function to hanlde the order edition
  */
  actionsMenuEditOrder = () => {
    const { activeOrderItemId, rightState, selectedCheckboxes } = this.state;
    const { history } = this.props;

    let orderId = activeOrderItemId;

    if (rightState === 'orderSelection') {
      orderId = selectedCheckboxes.values().next().value;
      this.setState({
        rightState: 'orderEdit',
        activeOrderItemId: orderId,
        activeOrderStatus: null,
        selectedCheckboxes: new Set(),
      });
    } else {
      this.setState({ rightState: 'orderEdit' });
    }

    history.push(`/orders/${orderId}/edit`);
  };

  /*
    Function to delete an order	    Function to cancel an order
  */
  actionsMenuOrderDelete = () => {
    const { activeOrderItemId, data, selectedCheckboxes } = this.state;
    let orderId;
    if (activeOrderItemId !== null) {
      orderId = activeOrderItemId;
    } else {
      orderId = selectedCheckboxes.values().next().value;
    }
    const activeIndex = data.findIndex(order => order.id === orderId);
    API.delete(`orders/${orderId}`)
      .then(() => {
        this.setState(prevState => ({
          data: update(prevState.data, { $splice: [[activeIndex, 1]] }),
          rightState: 'empty',
          activeOrderItemId: null,
          activeOrderStaus: null,
          selectedCheckboxes: new Set(),
        }));
      })
      .catch(error => {
        console.log(error);
      });
  };

  /*
    Function to cancel an order
  */
  actionsMenuOrderCancel = () => {
    const { history } = this.props;
    this.setState({
      rightState: 'orderCancel',
    });
    history.push('/orders/cancel');
  };

  cancelOrder = orderId => {
    const { data } = this.state;
    const activeIndex = data.findIndex(order => order.id === orderId);
    this.setState(prevState => ({
      data: update(prevState.data, { $splice: [[activeIndex, 1]] }),
      rightState: 'empty',
      activeOrderItemId: null,
      activeOrderStatus: null,
      selectedCheckboxes: new Set(),
    }));
  };

  /*
    Function to generate order report
  */
  actionsMenuGeneratePDF = (withPictures, type, label, inspectionId) => {
    const { activeOrderItemId, activeSubMenu, selectedCheckboxes } = this.state;
    const { showReportLoadingToast } = this.context;
    let entityType = inspectionId !== undefined ? 'inspections' : 'orders';
    let entityId = null;
    if (entityType === 'inspections') {
      entityId = inspectionId;
    } else if (activeOrderItemId !== null) {
      entityId = activeOrderItemId;
    } else {
      entityId = selectedCheckboxes.values().next().value;
    }
    if (activeSubMenu === 'independent') {
      entityType = 'inspections';
    }
    API.post(`${entityType}/${entityId}/report`, {
      withPictures,
      type,
      label,
    })
      .then(res => {
        if (res.data?.flag === 'cache') return;

        showReportLoadingToast({
          label: label || '',
          requestId: res.data?.request_id,
          withPictures: !!withPictures,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  actionsMenuGeneratePhotos = () => {
    const { activeOrderItemId, selectedCheckboxes } = this.state;
    const { showReportLoadingToast } = this.context;
    let entityId;
    if (activeOrderItemId !== null) {
      entityId = activeOrderItemId;
    } else {
      entityId = selectedCheckboxes.values().next().value;
    }
    const isInspection = !Number.isNaN(parseFloat(entityId)) && Number.isFinite(entityId);
    const entityType = isInspection ? 'inspections' : 'orders';
    API.post(`${entityType}/${entityId}/photos`, {})
      .then(res => {
        showReportLoadingToast({
          label: 'arquivo',
          requestId: res.data?.request_id,
          withPictures: true,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  actionsMenuOrderArchive = (e, action) => {
    const { activeOrderItemId, data, selectedCheckboxes } = this.state;
    let selectedOrders = [];
    if (selectedCheckboxes.size > 0) {
      selectedOrders = Array.from(selectedCheckboxes);
    } else if (activeOrderItemId !== null) {
      selectedOrders = [activeOrderItemId];
    } else {
      return;
    }

    API.post('batch/orders/archive', {
      action,
      orders: selectedOrders,
    })
      .then(() => {
        const orders = data.filter(order => !selectedOrders.includes(order.id));
        this.setState({
          data: orders,
          rightState: 'empty',
          activeOrderItemId: null,
          activeOrderStatus: null,
          selectedCheckboxes: new Set(),
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  /*
    Function to handle selection of a new order type from the order options
  */
  handleOrderOptionSelect = option => {
    const { data } = this.state;
    const { history } = this.props;
    const newData = update(data, {
      0: { type: { $set: option }, formatted_type: { $set: option } },
    });
    if (
      option === 'saida' ||
      option === 'saidaCurtaTemporada' ||
      option === 'conferencia' ||
      option === 'aditivo' ||
      option === 'revistoriaBrookfield' ||
      option === 'acompanhamentoObraMM' ||
      option === 'conferenciaFinalDeObra' ||
      option === 'intermediariaBHomy'
    ) {
      this.setState({
        data: newData,
        rightState: 'orderAttach',
        newOrderType: option,
      });

      history.push('/orders/new/attach');
    } else {
      this.setState({
        data: newData,
        rightState: 'orderForm',
        newOrderType: option,
      });

      history.push('/orders/new/form');
    }
  };

  triggerSearch = () => {
    const {
      activeSubMenu,
      headerSearchTags,
      headerSearchType,
      headerSearchValue,
      headerSearchOrderStatus,
      orderByDir,
      orderBy,
    } = this.state;

    this.setState({
      loadingOrders: true,
      rightState: 'empty',
      activeOrderItemId: null,
      newOrderType: null,
      previousOrderId: null,
      selectedCheckboxes: new Set(),
      actionsMenuChecked: false,
      hasMoreItems: true,
      previousOrderFile: null,
      isDisplayingSearchResults: true,
    });

    let url = '/orders';
    const params = {
      limit: ITEMS_PER_PAGE,
      order_by: orderBy,
      order_by_dir: orderByDir,
    };

    if (activeSubMenu === 'independent') {
      url = '/inspections';
      params.status = 'independent';
    }

    if (headerSearchValue !== '') params.search = headerSearchValue;

    if (headerSearchTags) params.tags = headerSearchTags;

    if (headerSearchType) params.type = headerSearchType;

    if (headerSearchOrderStatus) params.statusLabel = headerSearchOrderStatus;

    if (source !== null) source.cancel();

    source = API.CancelToken.source();

    API.get(url, { params, cancelToken: source.token }).then(res => {
      this.setState({
        loadingOrders: false,
        data: res.data,
      });
    });
  };

  /*
    Function to update order item based on the order form
  */
  updateOrderItem = data => {
    const { activeOrderItemId, data: stateData } = this.state;
    const activeIndex = stateData.findIndex(order => order.id === activeOrderItemId);
    const item = { ...stateData[activeIndex] };
    item.id = data.id;
    item.client = data.client;
    item.code = data.code;
    item.created_at = data.created_at.date;
    item.credits = data.credits;
    item.identifier = data.identifier;
    item.status = data.status === 'FINALIZED' ? 'FINALIZED_BY_DEVICE' : data.status;
    item.type = data.type;
    item.inspector = data.inspector;

    this.setState(prevState => ({
      data: update(prevState.data, { [activeIndex]: { $set: item } }),
    }));
  };

  /*
   * Function to cancel the new order
   */
  cancelNewOrder = () => {
    this.setState(prevState => ({
      data: update(prevState.data, { $splice: [[0, 1]] }),
      rightState: 'empty',
      newOrderType: null,
      activeOrderItemId: null,
      previousOrderId: null,
      previousOrderFile: null,
    }));
  };

  /*
   * Function to hanlde the association of an order to one order of the panel
   */
  orderAttachPanel = option => {
    const { history } = this.props;

    this.setState({
      rightState: 'orderForm',
      previousOrderId: option.id,
    });
    history.push('/orders/new/form');
  };

  /*
   * Function to handle order creation without previous inspection
   */
  continueWithoutAttachment = option => {
    const { history } = this.props;

    this.setState({
      rightState: 'orderForm',
      previousOrderId: null,
    });
    history.push('/orders/new/form');
  };

  independentAttach = option => {
    const { activeOrderItemId } = this.state;
    const inspectionId = activeOrderItemId;
    const orderId = option.id;
    API.put(`/inspections/${inspectionId}/order`, { order_id: orderId })
      .then(() => {
        this.refreshData();
      })
      .catch(error => {
        console.log(error);
      });
  };

  /*
   * Function to hanlde the association of a file to an order
   */
  orderAttachFile = (fileExtension, originalName, remoteName) => {
    const { history } = this.props;

    const previousOrderFile = {
      file: {
        original_name: originalName,
        remote_name: remoteName,
        extension: fileExtension,
      },
    };
    this.setState({
      rightState: 'orderForm',
      previousOrderFile,
    });

    history.push('/orders/new/form');
  };

  /*
   * Function to handle the successful new order creation,
   * hide the form and show the new order details
   */
  successfulOrderCreation = data => {
    const { data: stateData } = this.state;
    const { history } = this.props;

    // Update the id of the new order item
    const newData = update(stateData, { 0: { id: { $set: data.id } } });
    this.setState({
      data: newData,
      rightState: 'orderDetails',
      activeOrderItemId: data.id,
      activeOrderStatus: data.status,
      newOrderType: null,
      previousOrderId: null,
    });

    history.push(`/orders/${data.id}`);
  };

  /*
   * Function to handle the successful order edition,
   * hide the form and show the order details
   */
  successfulOrderEdition = () => {
    const { history } = this.props;
    const { activeOrderItemId } = this.state;

    this.setState({ rightState: 'orderDetails' });

    history.push(`/orders/${activeOrderItemId}`);
  };

  /*
   * Function to cancel the order edition
   */
  cancelEditOrder = () => {
    const { history } = this.props;

    this.setState({
      rightState: 'empty',
      activeOrderItemId: null,
    });

    history.push('/orders');
  };

  /*
   * Function to load more items for the infinite scroll
   */
  loadMoreItems = page => {
    const {
      activeSubMenu,
      hasMoreItems,
      headerSearchTags,
      headerSearchType,
      headerSearchValue,
      isDisplayingSearchResults,
      orderByDir,
      orderBy,
    } = this.state;

    if (!hasMoreItems) return;

    const offset = page * ITEMS_PER_PAGE;

    let url = '/orders';
    const params = {
      limit: ITEMS_PER_PAGE,
      offset,
      order_by: orderBy,
      order_by_dir: orderByDir,
    };

    if (!isDisplayingSearchResults || activeSubMenu === 'independent') params.status = status.get(activeSubMenu);

    if (activeSubMenu === 'independent') url = '/inspections';

    if (headerSearchValue !== '') params.search = headerSearchValue;

    if (headerSearchTags) params.tags = headerSearchTags;

    if (headerSearchType) params.type = headerSearchType;

    API.get(url, { params }).then(res => {
      if (res.data?.length) {
        this.setState(prevState => ({
          data: update(prevState.data, { $push: res.data }),
          hasMoreItems: res.data.length === ITEMS_PER_PAGE,
        }));

        return;
      }

      this.setState({ hasMoreItems: false });
    });
  };

  /*
   * Function to reject an order
   */
  rejectOrder = message => {
    const { activeOrderItemId, data } = this.state;
    const { history } = this.props;
    const activeIndex = data.findIndex(order => order.id === activeOrderItemId);

    this.setState({ requestInProgress: true });
    API.post(`orders/${activeOrderItemId}/status`, {
      action: 'reject',
      message,
    })
      .then(() => {
        this.setState(prevState => ({
          data: update(prevState.data, { $splice: [[activeIndex, 1]] }),
          rightState: 'empty',
          activeOrderItemId: null,
        }));
        history.push('/orders');
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ requestInProgress: false });
      });
  };

  /**
   * Function to open the search device component
   */
  openAssignDevice = () => {
    const { history } = this.props;

    this.setState({ rightState: 'orderAssign' });

    history.push('/orders/assign');
  };

  /**
   * Function to asign a device to an order
   */
  assignDeviceToOrder = formValues => {
    const { activeOrderItemId } = this.state;
    const { history } = this.props;

    this.setState({ apiStatus: 'WORKING' });
    API.post(`/orders/${activeOrderItemId}/assign`, formValues)
      .then(res => {
        this.updateOrderItem({ ...res.data });
        this.setState({
          rightState: 'orderDetails',
          activeOrderItemId: res.data.id,
          activeOrderStatus: res.data.status,
          apiStatus: 'IDLE',
        });

        history.push(`/orders/${res.data.id}`);
      })
      .catch(error => {
        console.log(error);
        this.setState({ apiStatus: 'ERROR' });
      });
  };

  /**
   * Function to open the search order/ inspection
   */
  openAssignOrder = type => {
    const { history } = this.props;

    this.setState({
      rightState: 'independentAssign',
      currentInspectionType: type,
    });

    history.push('/orders/independent-assign');
  };

  /**
   * Set current orderId, from URL
   */
  setOrderIdFromUrl = (orderId = null) => {
    this.setState({ orderIdFromUrl: orderId });
  };

  toggleModal = modalName => {
    this.setState(({ modals }) => ({
      modals: {
        ...modals,
        [modalName]: !modals[modalName],
      },
    }));
  };

  render() {
    const {
      actionsMenuChecked,
      activeOrderItemId,
      activeOrderStatus,
      activeSubMenu,
      activeSubMenuIndex,
      apiStatus,
      currentInspectionType,
      data,
      hasMoreItems,
      headerSearchValue,
      loadingOrders,
      menu,
      newOrderType,
      previousOrderFile,
      previousOrderId,
      rightState,
      selectedCheckboxes,
      orderIdFromUrl,
      isDisplayingSearchResults,
      orderByDir,
      modals,
    } = this.state;
    const { classes, features, franchiseeId, intl, ordersChanged, userName } = this.props;

    let orderID;
    let orderStatus;
    let orderType;
    let isFromTheSameFranchisee = false;
    let isArchived = false;
    let flags = null;

    if (selectedCheckboxes.size === 1) {
      orderID = selectedCheckboxes.values().next().value;
    } else {
      orderID = activeOrderItemId;
    }

    const activeIndex = data.findIndex(order => order.id === orderID);
    let inspectionIcon;

    if (activeIndex !== -1) {
      inspectionIcon = <OrderIcon orderType={data[activeIndex].type} fontSize="calc(16px + 0.8vw)" />;
      orderStatus = data[activeIndex].status;
      orderType = data[activeIndex].type;
      flags = data[activeIndex].flags;
      isArchived = data[activeIndex].is_archived ?? false;
      isFromTheSameFranchisee = data[activeIndex]?.franchisee_id === franchiseeId || status.get(activeSubMenu) === 'independent';
    }

    let multipleArchiveAllowed = false;
    if (selectedCheckboxes.size > 1 && status.get(activeSubMenu) !== 'independent') {
      const selectedItems = data.filter(item => selectedCheckboxes.has(item.id));
      multipleArchiveAllowed = selectedItems.reduce((prev, current) => {
        const isNotArchivableStatus =
          selectedCheckboxes.has(current.id) &&
          ['FINISHED', 'REJECTED', 'CANCELED', 'WAITING'].includes(current.status);
        return prev && isNotArchivableStatus === false && current.is_archived === isArchived;
      }, true);
    }

    return (
      <div>
        <ApiContext.Provider value={{ status: apiStatus }}>
          <LeftDrawer
            menu={menu}
            handleMenuChange={this.handleMenuChange}
            handleSubMenuChange={this.handleSubMenuChange}
            handleCollapseSubMenuChange={this.handleCollapseSubMenuChange}
            isDisplayingSearchResults={isDisplayingSearchResults}
            subMenuToOpenByIndex={activeSubMenuIndex}
          />
          <Header
            headerSearchValue={headerSearchValue}
            handleHeaderSearch={this.handleHeaderSearch}
            userName={userName}
            activeSubMenu={currentPageByCurrentMenu.get(activeSubMenu)}
            xs={12}
            md={12}
          />
          <Grid container classes={{ container: classes.mainPage }} alignContent="flex-start">
            <Grid item xs={12} classes={{ item: classes.actionsMenu }}>
              <ActionsMenu
                orderByDir={orderByDir}
                changeOrderByDir={this.changeOrderByDir}
                orderId={orderID}
                orderType={orderType}
                orderStatus={orderStatus}
                actionHandler={this.callActionHandler}
                multipleArchiveAllowed={multipleArchiveAllowed}
                checkAll={this.actionsMenuCheckAllAction}
                newOrderAction={this.actionsMenuNewOrder}
                checked={actionsMenuChecked}
                disableCheckbox={
                  rightState === 'orderOptions' ||
                  rightState === 'orderForm' ||
                  rightState === 'orderAttach' ||
                  rightState === 'orderEdit'
                }
                currentPage={currentPageByCurrentMenu.get(activeSubMenu)}
                isArchived={isArchived}
                isFromTheSameFranchisee={isFromTheSameFranchisee}
                flags={flags}
              />
            </Grid>
            <Grid item xs={12} md={6} classes={{ item: classes.scroll }}>
              {loadingOrders ? (
                <Loading />
              ) : data.length ? (
                <OrdersList
                  orderIdFromUrl={orderIdFromUrl}
                  orderShowDetailsAction={this.orderShowDetailsAction}
                  data={data}
                  checkOne={this.checkOneItemAction}
                  selectedCheckboxes={selectedCheckboxes}
                  selectedItem={activeIndex}
                  loadMoreItems={this.loadMoreItems}
                  hasMoreItems={hasMoreItems}
                  showListRefresh={ordersChanged}
                  refreshData={this.refreshData}
                  fromPage={activeSubMenu}
                />
              ) : (
                <Empty
                  header={<FormattedMessage id="empty.listHeader" />}
                  subHeader={<FormattedMessage id="empty.listSubHeader" />}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} classes={{ item: classes.scroll }}>
              <Switch>
                <Route
                  exact
                  path="/orders"
                  render={() => (
                    <Empty
                      header={<FormattedMessage id="empty.selectRequest" />}
                      subHeader={<FormattedMessage id="empty.createRequest" />}
                    />
                  )}
                />
                <Route
                  path="/orders/cancel"
                  render={() => {
                    if (!data[activeIndex]) {
                      const { history } = this.props;
                      history.replace('/orders');
                      return null;
                    }

                    return (
                      <OrderCancelPage
                        order={data[activeIndex]}
                        inspectionIcon={inspectionIcon}
                        cancelOrder={this.cancelOrder}
                        changePage={this.callActionHandler}
                      />
                    );
                  }}
                />
                <Route
                  path="/orders/new/form"
                  render={() => (
                    <OrderFormPage
                      previousOrderFile={previousOrderFile}
                      inspectionIcon={inspectionIcon}
                      newOrderType={newOrderType}
                      updateOrderItem={this.updateOrderItem}
                      cancelNewOrder={this.cancelNewOrder}
                      previousOrderId={previousOrderId}
                      successfulOrderCreation={this.successfulOrderCreation}
                    />
                  )}
                />
                <Route
                  path="/orders/new/attach"
                  render={() => {
                    const { history } = this.props;
                    let previousOrderType = null;
                    let header = null;
                    let previousType = null;

                    if (!newOrderType) {
                      history.replace('/orders/new');
                      return null;
                    }

                    if (newOrderType === 'saida') {
                      previousOrderType = "['entrada','aditivo']";
                      header = 'inspection.saida';
                      previousType = intl.formatMessage({
                        id: 'entradaOrAditivo',
                      });
                    } else if (newOrderType === 'saidaCurtaTemporada') {
                      previousOrderType = "['entradaCurtaTemporada']";
                      header = 'inspection.saidaCurtaTemporada';
                      previousType = intl.formatMessage({
                        id: 'entradaCurtaTemporada',
                      });
                    } else if (newOrderType === 'conferencia') {
                      previousOrderType = "['saida','conferencia']";
                      header = 'inspection.conferencia';
                      previousType = intl.formatMessage({
                        id: 'saidaOrConferencia',
                      });
                    } else if (newOrderType === 'conferenciaFinalDeObra') {
                      previousOrderType = "['finalDeObra','conferenciaFinalDeObra']";
                      header = 'inspection.conferenciaFinalDeObra';
                      previousType = intl.formatMessage({
                        id: 'finalDeObraOrConferenciaFinalDeObra',
                      });
                    } else if (newOrderType === 'aditivo') {
                      previousOrderType = "['entrada', 'aditivo']";
                      header = 'inspection.aditivo';
                      previousType = intl.formatMessage({
                        id: 'entradaOrAditivo',
                      });
                    } else if (newOrderType === 'revistoriaBrookfield') {
                      previousOrderType = "['brookfield', 'revistoriaBrookfield']";
                      header = 'inspection.brookfield';
                      previousType = intl.formatMessage({
                        id: 'brookfieldOrRevistoria',
                      });
                    } else if (newOrderType === 'acompanhamentoObraMM') {
                      previousOrderType = 'acompanhamentoObraMM';
                      header = 'inspection.acompanhamentoObraMM';
                      previousType = intl.formatMessage({
                        id: previousOrderType,
                      });
                    } else if (newOrderType === 'intermediariaBHomy') {
                      previousOrderType = "['entradaBHomy','intermediariaBHomy']";
                      header = 'inspection.intermediariaBHomy';
                      previousType = intl.formatMessage({ id: 'entradaBHomyOrIntermediariaBHomy' });
                    }

                    return (
                      <InspectionAttach
                        header={<FormattedMessage id={header} />}
                        subHeader={
                          <FormattedMessage
                            id={
                              newOrderType !== 'acompanhamentoObraMM'
                                ? 'inspectionAttach.entry'
                                : 'inspectionAttach.acompanhamento'
                            }
                            values={{ type: previousType }}
                          />
                        }
                        handleSearchConfirm={this.orderAttachPanel}
                        handleFileConfirm={this.orderAttachFile}
                        handleContinueWithouAttachment={this.continueWithoutAttachment}
                        searchPlaceholder={intl.formatMessage(
                          { id: 'inspectionAttach.searchEntry' },
                          { type: previousType.toLowerCase() }
                        )}
                        apiUrl={`/orders?type=${previousOrderType}&status=sent&search=`}
                        previousType={previousType}
                        newOrderType={newOrderType}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/orders/new"
                  render={() => (
                    <OrderOptionsList
                      handleOrderOptionSelect={this.handleOrderOptionSelect}
                      features={features}
                      withDescription={false}
                    />
                  )}
                />
                <Route
                  path="/orders/independent-assign"
                  render={() => (
                    <InspectionAttach
                      header={<FormattedMessage id="inspection.independent" />}
                      subHeader={<FormattedMessage id="inspectionAttach.order" />}
                      handleSearchConfirm={this.independentAttach}
                      searchPlaceholder={intl.formatMessage({
                        id: 'orderAttach.searchPanel',
                      })}
                      apiUrl={`/orders?type=${currentInspectionType}&status=accepted_active&search=`}
                      onlySearch
                    />
                  )}
                />
                <Route
                  path="/orders/selection"
                  render={() => (
                    <OrderSelectionMultiple
                      itemsAmount={selectedCheckboxes.size}
                      actionHandler={this.callActionHandler}
                      orderId={orderID}
                      orderType={orderType}
                      orderStatus={orderStatus}
                      multipleArchiveAllowed={multipleArchiveAllowed}
                      currentPage={currentPageByCurrentMenu.get(activeSubMenu)}
                      flags={flags}
                      isArchived={isArchived}
                    />
                  )}
                />
                <Route
                  path="/orders/assign"
                  render={() => (
                    <AssignInspector assignDeviceToOrder={this.assignDeviceToOrder} cancelHandler={this.refreshData} />
                  )}
                />
                <Route
                  path="/orders/:orderId/performing-inspector"
                  render={props => <AddPerformingInspector orderId={props.match.params.orderId} />}
                />
                <Route
                  path="/orders/extra-actions"
                  render={() => (
                    <ExtraActions
                      changePage={this.callActionHandler}
                      orderData={activeIndex > -1 ? data[activeIndex] : null}
                      orderId={orderID}
                      orderType={orderType}
                      clientPanel={false}
                    />
                  )}
                />
                <Route
                  path="/orders/checklist"
                  render={() => (
                    <ChecklistForm
                      changePage={this.callActionHandler}
                      orderId={orderID}
                      clientPanel={false}
                      actionHandler={this.callActionHandler}
                    />
                  )}
                />
                <Route
                  path="/orders/:orderId/repair-budget"
                  render={() => (
                    <RepairBudgetForm
                      changePage={this.callActionHandler}
                      orderId={orderID}
                      clientPanel={false}
                      actionHandler={this.callActionHandler}
                    />
                  )}
                />
                <Route path="/orders/error" component={ErrorPage} />
                <Route
                  exact
                  path="/(orders|inspections)/:orderId"
                  render={props => (
                    <OrderDetails
                      notifyExistingOrderIdInUrl={this.setOrderIdFromUrl}
                      changePage={this.callActionHandler}
                      decrementBadge={this.decrementBadge}
                      updateOrderItem={this.updateOrderItem}
                      rejectOrder={this.rejectOrder}
                      openAssignDevice={this.openAssignDevice}
                      openAssignOrder={this.openAssignOrder}
                      orderStatus={activeOrderStatus}
                      refreshData={this.refreshData}
                      showError={() => this.showInspectionErrorHandler()}
                      actionsMenuGeneratePDF={this.actionsMenuGeneratePDF}
                      key={props.match.params.orderId}
                      currentPageByOrderStatus={currentPageByOrderStatus}
                    />
                  )}
                />
                <Route
                  exact
                  path="/(orders|inspections)/:orderId/edit"
                  render={() => (
                    <EditOrderFormPage
                      inspectionIcon={inspectionIcon}
                      updateOrderItem={this.updateOrderItem}
                      successfulOrderEdition={this.successfulOrderEdition}
                      cancelEditOrder={this.cancelEditOrder}
                      orderStatus={orderStatus}
                      clientPanel={false}
                    />
                  )}
                />
              </Switch>
            </Grid>
          </Grid>

          {modals.extract && (
            <Modal onClose={() => this.toggleModal('extract')} extraStyles={{ overflowY: 'visible' }}>
              <OrdersReportsModal closeModal={() => this.toggleModal('extract')} />
            </Modal>
          )}
        </ApiContext.Provider>
      </div>
    );
  }
}

FranchiseeApp.propTypes = {
  location: PropTypes.object.isRequired,
  userName: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  menu: PropTypes.object,
  ordersChanged: PropTypes.bool.isRequired,
  ordersUpdated: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
  franchiseeId: PropTypes.number.isRequired
};

FranchiseeApp.defaultProps = {
  menu: null,
};

export default connect(null, { setQuery })(withStyles(styles)(withRouter(injectIntl(FranchiseeApp))));
