import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import moment from 'moment';
import PropTypes from 'prop-types';

import api from 'src/apiRequest';
import { useUser } from 'src/contexts/UserContext';

import ActivityLogComponent from './ActivityLogComponent';

const ActivityLogContainer = ({ orderId }) => {
  const [log, setLog] = useState([]);
  const intl = useIntl();
  const user = useUser();

  useEffect(() => {
    let isMounted = true; // Flag to track if the component is still mounted

    const fetchLog = async () => {
      try {
        var { data } = await api.get(`activity-log/orders/${orderId}/actions`);

        if (!isMounted) return;

        setLog(
          data.map(d => {
            const date = moment(d.created_at.date);
            return {
              ...d,
              humanDate: date.fromNow(),
              date: date.format('DD/MM/YYYY [às] HH:mm'),
            };
          })
        );
      } catch (err) {
        console.log(err);
      }
    };

    fetchLog();
    return () => {
      isMounted = false;
    };
  }, [orderId, user?.authRole]);

  return log.length > 0 ? <ActivityLogComponent intl={intl} log={log} /> : null;
};

ActivityLogContainer.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default ActivityLogContainer;
