import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import PropTypes from 'prop-types';

import classes from 'src/components/OrderCardType/OrderCardType.module.scss';
import faqImage from 'src/images/icons/circle-faq-dark.svg';

import OrderIcon from '../OrderIcon/OrderIcon';

const OrderCardType = ({ type, withDescription, hasHelp, handleClick }) => {
  const intl = useIntl();
  return (
    <div
      className={`${classes.orderCardType} ${!withDescription ? classes.noDescription : ''}`}
      onClick={handleClick}
    >
      <div className={classes.title}>
        <div className={classes.icon}>
          <OrderIcon orderType={type} />
        </div>
        <h1>
          <FormattedMessage id={type} />
        </h1>
        {hasHelp && (
          <div className={classes.helpContainer}>
            <a
              href="https://gruporv.movidesk.com/kb"
              className={classes.help}
              target="_blank"
              rel="noreferrer noopener"
              onClick={evt => evt.stopPropagation()}
              title={intl.formatMessage({ id: 'orderOptionList.iconFaq' })}
            >
              <img src={faqImage} alt="FAQ" />
            </a>
          </div>
        )}
      </div>
      {withDescription && (
        <div className={classes.description}>
          <FormattedMessage id={`description.${type}`} defaultMessage=" " />
        </div>
      )}
    </div>
  );
};

OrderCardType.propTypes = {
  /**
   * String with namme of the displayed option
   */
  type: PropTypes.string.isRequired,
  /**
   * Boolean to know if the button have the order type description
   */
  withDescription: PropTypes.bool.isRequired,
  /**
   * Boolean to know if display the link to the faq
   */
  hasHelp: PropTypes.bool.isRequired,
  /**
   * Function that directs the user to the option page
   */
  handleClick: PropTypes.func.isRequired,
};

export default OrderCardType;
