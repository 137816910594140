import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/DetailsSection.module.css';
import Details from 'src/svg-icons/details';

function DetailsSection({ details }) {
  const mappedDetails = Object.keys(details).map((detail, index) => {
    let value = null;
    if (details[detail] === null || details[detail] === '' || detail === 'price') {
      return null;
    }
    switch (detail) {
      case 'area':
        value = `${details[detail]} m²`;
        break;
      case 'price':
        value = `${details[detail]} créditos`;
        break;
      case 'furnished':
        value = <FormattedMessage id={details[detail]} />;
        break;
      default:
        value = details[detail];
        break;
    }
    return (
      <Grid item key={index} xs={6}>
        <div className={classes.SubHeader}>
          <FormattedMessage id={detail} />
        </div>
        {value}
      </Grid>
    );
  });
  return (
    <Grid item xs={12}>
      <div className={classes.Header}>
        <Details className={classes.Icon} />
        <FormattedMessage id="details" />
      </div>
      <Grid container spacing={8} className={classes.Information} data-cy="order-details-section">
        {mappedDetails}
      </Grid>
    </Grid>
  );
}

DetailsSection.propTypes = {
  /**
   * Object that contains the details information
   */
  details: PropTypes.object.isRequired,
};

export default DetailsSection;
