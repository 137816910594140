import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/AccompaniedInspectionSection/style.module.scss';
import TourVirtualAcessInfo from 'src/svg-icons/TourVirtualAcessInfo';

const TourAccessInfo = ({ data }) => {
  return (
    <Grid item xs={12}>
      <div className={classes.header}>
        <TourVirtualAcessInfo className={classes.icon} />
        <FormattedMessage id="tourAccessInfo" />
      </div>
      <Grid container spacing={8} className={classes.information}>
        {data.login && (
          <Grid item xs={12} data-cy="order-details-tour-access-login">
            <div className={classes.subHeader}>
              <FormattedMessage id="login" />
            </div>
            {data.login}
          </Grid>
        )}
        {data.password && (
          <Grid item xs={12} data-cy="order-details-tour-access-password">
            <div className={classes.subHeader}>
              <FormattedMessage id="password" />
            </div>
            {data.password}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

TourAccessInfo.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TourAccessInfo;
