import type5asec from 'src/images/icons/type-5asec.svg';
import typeAcompanhamentoObraSeazone from 'src/images/icons/type-acompanhamento-obra-seazone.svg';
import typeAcompanhamentoObraMM from 'src/images/icons/type-acompanhamentoObraMM.svg';
import typeAditivo from 'src/images/icons/type-aditivo.svg';
import typeIntermediariaBHomy from 'src/images/icons/type-aditivo.svg';
import typeAtestado from 'src/images/icons/type-atestado.svg';
import typeAuditoriaLoja from 'src/images/icons/type-auditoriaLoja.svg';
import typeAvaliacao from 'src/images/icons/type-avaliacao.svg';
import typeAvaliacaoHousi from 'src/images/icons/type-avaliacaoHousi.svg';
import typeAvaliacaoImobiliaria from 'src/images/icons/type-avaliacaoImobiliaria.svg';
import typeAvaliacaoZap from 'src/images/icons/type-avaliacaoZap.svg';
import typeCautelar from 'src/images/icons/type-cautelar.svg';
import typeCautelarAterpa from 'src/images/icons/type-cautelarAterpa.svg';
import typeCautelarCCInfra from 'src/images/icons/type-cautelarCCInfra.svg';
import typeCautelarQueirozGalvao from 'src/images/icons/type-cautelarQueirozGalvao.svg';
import typeChaves from 'src/images/icons/type-chaves.svg';
import typeChecklistSamsung from 'src/images/icons/type-checklistSamsung.svg';
import typeConferencia from 'src/images/icons/type-conferencia.svg';
import typeConferenciaFinalDeObra from 'src/images/icons/type-conferenciaFinalDeObra.svg';
import typeCurtaTemporada from 'src/images/icons/type-curta-temporada.svg';
import typeEntradaCurtaTemporada from 'src/images/icons/type-entrada-curta-temporada.svg';
import typeEntrada from 'src/images/icons/type-entrada.svg';
import typeEntradaBHomy from 'src/images/icons/type-entradaBHomy.svg';
import typeEntradaLight from 'src/images/icons/type-entradaLight.svg';
import typeEntrega from 'src/images/icons/type-entrega.svg';
import typeEntregaHacasa from 'src/images/icons/type-entrega.svg';
import typeFichaCerta from 'src/images/icons/type-fichacerta.svg';
import typeFinalDeObra from 'src/images/icons/type-finalDeObra.svg';
import typeFotos from 'src/images/icons/type-fotos.svg';
import typeInspecaoPredial from 'src/images/icons/type-inspecaoPredial.svg';
import typeInventario from 'src/images/icons/type-inventario.svg';
import typeMedicaoObraTrinus from 'src/images/icons/type-medicao-obra-trinus.svg';
import typeMedicao from 'src/images/icons/type-medicao.svg';
import typeNone from 'src/images/icons/type-none.svg';
import typePiloto from 'src/images/icons/type-piloto.svg';
import typePredial from 'src/images/icons/type-predial.svg';
import typePredialO2 from 'src/images/icons/type-predialO2.svg';
import typePreVistoriaBHomy from 'src/images/icons/type-preVistoriaBHomy.svg';
import typeSaidaCurtaTemporada from 'src/images/icons/type-saida-curta-temporada.svg';
import typeSaida from 'src/images/icons/type-saida.svg';
import typeSeguroLever from 'src/images/icons/type-seguro-lever.svg';
import typeSignature from 'src/images/icons/type-signature.svg';
import typeTombamento from 'src/images/icons/type-tombamento.svg';
import typeTourVirtual from 'src/images/icons/type-tour-virtual.svg';
import typeTransferencia from 'src/images/icons/type-transferencia.svg';
import typeVisitaDigital from 'src/images/icons/type-visita-digital.svg';

const icons = {
  '5asec': type5asec,
  acompanhamentoObraMM: typeAcompanhamentoObraMM,
  aditivo: typeAditivo,
  auditoriaLoja: typeAuditoriaLoja,
  auditoriaVaapty: typeAuditoriaLoja,
  fast4You: typeAuditoriaLoja,
  smartEsquadrias: typeAuditoriaLoja,
  levantamentoVivara: typeAuditoriaLoja,
  auditoriaAbcConstrucao: typeAuditoriaLoja,
  atestado: typeAtestado,
  avaliacao: typeAvaliacao,
  avaliacaoHousi: typeAvaliacaoHousi,
  avaliacaoImobiliaria: typeAvaliacaoImobiliaria,
  avaliacaoZap: typeAvaliacaoZap,
  cautelar: typeCautelar,
  cautelarAterpa: typeCautelarAterpa,
  cautelarCCInfra: typeCautelarCCInfra,
  cautelarQueirozGalvao: typeCautelarQueirozGalvao,
  chaves: typeChaves,
  checklistSamsung: typeChecklistSamsung,
  conferencia: typeConferencia,
  conferenciaFinalDeObra: typeConferenciaFinalDeObra,
  constatacao: typeEntrada,
  entrada: typeEntrada,
  entradaBHomy: typeEntradaBHomy,
  entradaLight: typeEntradaLight,
  entrega: typeEntrega,
  entregaHacasa: typeEntregaHacasa,
  finalDeObra: typeFinalDeObra,
  inspecaoPredial: typeInspecaoPredial,
  inventario: typeInventario,
  medicao: typeMedicao,
  piloto: typePiloto,
  predial: typePredial,
  predialO2: typePredialO2,
  preVistoriaBHomy: typePreVistoriaBHomy,
  saida: typeSaida,
  signature: typeSignature,
  tombamento: typeTombamento,
  transferencia: typeTransferencia,
  visitaDigital: typeVisitaDigital,
  fotos: typeFotos,
  fotosDeCaptacao: typeFotos,
  seguroLever: typeSeguroLever,
  tourVirtual: typeTourVirtual,
  intermediariaBHomy: typeIntermediariaBHomy,
  entradaCurtaTemporada: typeEntradaCurtaTemporada,
  saidaCurtaTemporada: typeSaidaCurtaTemporada,
  fichaCerta: typeFichaCerta,
  curtaTemporada: typeCurtaTemporada,
  acompanhamentoObraSeazone: typeAcompanhamentoObraSeazone,
  medicaoObraTrinus: typeMedicaoObraTrinus,
};

function orderIcon(orderType) {
  return icons?.[orderType] || typeNone;
}

export default orderIcon;
