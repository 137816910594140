import React from 'react';

import PropTypes from 'prop-types';

import EletronicSign from 'src/svg-icons/eletronic-sign';
import Type5Asec from 'src/svg-icons/type-5asec';
import TypeAcompanhamentoObraSeazone from 'src/svg-icons/type-acompanhamento-obra-seazone';
import TypeAcompanhamentoObraMM from 'src/svg-icons/type-acompanhamentoObraMM';
import TypeAditivo from 'src/svg-icons/type-aditivo';
import TypeAuditoriaLoja from 'src/svg-icons/type-auditoriaLoja';
import TypeAuditoriaMM from 'src/svg-icons/type-auditoriaLoja';
import TypeAvaliacao from 'src/svg-icons/type-avaliacao';
import TypeAvaliacaoImobiliaria from 'src/svg-icons/type-avaliacao-imobiliaria';
import TypeAvaliacaoHousi from 'src/svg-icons/type-avaliacaoHousi';
import TypeAvaliacaoZap from 'src/svg-icons/type-avaliacaoZap';
import TypeCautelar from 'src/svg-icons/type-cautelar';
import TypeCautelarAterpa from 'src/svg-icons/type-cautelarAterpa';
import TypeCautelarCCInfra from 'src/svg-icons/type-cautelarCCInfra';
import TypeCautelarQueirozGalvao from 'src/svg-icons/type-cautelarQueirozGalvao';
import TypeEntrega from 'src/svg-icons/type-chaves';
import TypeEntregaHacasa from 'src/svg-icons/type-chaves';
import TypeChecklistSamsung from 'src/svg-icons/type-checklistSamsung';
import TypeConferencia from 'src/svg-icons/type-conferencia';
import TypeConferenciaFinalDeObra from 'src/svg-icons/type-conferenciaFinalDeObra';
import TypeCurtaTemporada from 'src/svg-icons/type-curta-temporada';
import TypeEntrada from 'src/svg-icons/type-entrada';
import TypeEntradaCurtaTemporada from 'src/svg-icons/type-entrada-curta-temporada';
import TypeEntradaBHomy from 'src/svg-icons/type-entradaBHomy';
import TypeEntradaLight from 'src/svg-icons/type-entradaLight';
import TypeFichaCerta from 'src/svg-icons/type-fichacerta';
import TypeFinalDeObra from 'src/svg-icons/type-finalDeObra';
import TypeFotosDeCaptacao from 'src/svg-icons/type-fotos-de-captacao';
import TypeIntermediariaBHomy from 'src/svg-icons/type-intermediariaBHomy';
import TypeInventario from 'src/svg-icons/type-inventario';
import TypeMedicaoObraTrinus from 'src/svg-icons/type-medicao-obra-trinus';
import TypeNone from 'src/svg-icons/type-none';
import TypePiloto from 'src/svg-icons/type-piloto';
import TypePredial from 'src/svg-icons/type-predial';
import TypePredialO2 from 'src/svg-icons/type-predialO2';
import TypePreVistoriaBHomy from 'src/svg-icons/type-preVistoriaBHomy';
import TypeSaida from 'src/svg-icons/type-saida';
import TypeSaidaCurtaTemporada from 'src/svg-icons/type-saida-curta-temporada';
import TypeSeguroLever from 'src/svg-icons/type-seguro-lever';
import TypeTombamento from 'src/svg-icons/type-tombamento';
import TypeTourVirtual from 'src/svg-icons/type-tour-virtual';
import TypeTransferencia from 'src/svg-icons/type-transferencia';
import TypeVisitaDigital from 'src/svg-icons/type-visita-digital';

const firstDefaultViewBox = '0 0 34.016 34.016';
const secondDefaultViewBox = '0 0 69 70';
const defaultStyles = { verticalAlign: 'middle', marginRight: '10px' };

const orderTypeIconMapper = {
  conferencia: fontSize => <TypeConferencia viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_CONFERENCIA: fontSize => (
    <TypeConferencia viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  conferencia_legacy: fontSize => (
    <TypeConferencia viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  saida: fontSize => <TypeSaida viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_SAIDA: fontSize => <TypeSaida viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  saida_legacy: fontSize => <TypeSaida viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  entrada: fontSize => <TypeEntrada viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_ENTRADA: fontSize => <TypeEntrada viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  constatacao: fontSize => <TypeEntrada viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_CONSTATACAO: fontSize => <TypeEntrada viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  finalDeObra: fontSize => <TypeFinalDeObra viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_FINAL: fontSize => <TypeFinalDeObra viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_FINAL_DE_OBRA: fontSize => (
    <TypeFinalDeObra viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  entrega: fontSize => <TypeEntrega viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_ENTREGA: fontSize => <TypeEntrega viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  entregaHacasa: fontSize => <TypeEntregaHacasa viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_ENTREGA_HACASA: fontSize => (
    <TypeEntregaHacasa viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  transferencia: fontSize => <TypeTransferencia viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_TRANSFERENCIA: fontSize => (
    <TypeTransferencia viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  predial: fontSize => <TypePredial viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_PREDIAL: fontSize => <TypePredial viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  marketplace_docusign: fontSize => (
    <EletronicSign viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_DOCUSIGN: fontSize => <EletronicSign viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  avaliacaoImobiliaria: fontSize => (
    <TypeAvaliacaoImobiliaria viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_AVALIACAO_IMOBILIARIA: fontSize => (
    <TypeAvaliacaoImobiliaria viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  visitaDigital: fontSize => <TypeVisitaDigital style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_VISITA_DIGITAL: fontSize => <TypeVisitaDigital style={{ fontSize, ...defaultStyles }} />,
  fotosDeCaptacao: fontSize => <TypeFotosDeCaptacao style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_FOTOS_DE_CAPTACAO: fontSize => <TypeFotosDeCaptacao style={{ fontSize, ...defaultStyles }} />,
  seguroLever: fontSize => <TypeSeguroLever style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_SEGURO_LEVER: fontSize => <TypeSeguroLever style={{ fontSize, ...defaultStyles }} />,
  tourVirtual: fontSize => <TypeTourVirtual style={{ fontSize, padding: '2px', ...defaultStyles }} />,
  VISTORIA_TOUR_VIRTUAL: fontSize => <TypeTourVirtual style={{ fontSize, padding: '2px', ...defaultStyles }} />,
  VISTORIA_5ASEC: fontSize => <Type5Asec viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  '5asec': fontSize => <Type5Asec viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_ACOMPANHAMENTO_OBRA_MM: fontSize => (
    <TypeAcompanhamentoObraMM viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  acompanhamentoObraMM: fontSize => (
    <TypeAcompanhamentoObraMM viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_ADITIVO: fontSize => <TypeAditivo viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  aditivo: fontSize => <TypeAditivo viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_AUDITORIA_LOJA: fontSize => (
    <TypeAuditoriaLoja viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  auditoriaLoja: fontSize => (
    <TypeAuditoriaLoja viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_AUDITORIA_VAAPTY: fontSize => (
    <TypeAuditoriaLoja viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  auditoriaVaapty: fontSize => (
    <TypeAuditoriaLoja viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  fast4You: fontSize => (
    <TypeAuditoriaLoja viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  smartEsquadrias: fontSize => (
    <TypeAuditoriaLoja viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  levantamentoVivara: fontSize => (
    <TypeAuditoriaLoja viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_AUDITORIA_ABC_CONSTRUCAO: fontSize => (
    <TypeAuditoriaLoja viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  auditoriaAbcConstrucao: fontSize => (
    <TypeAuditoriaLoja viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_AUDITORIA_MM: fontSize => (
    <TypeAuditoriaMM viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  auditoriaMM: fontSize => <TypeAuditoriaMM viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_AVALIACAO: fontSize => (
    <TypeAvaliacao viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  avaliacao: fontSize => <TypeAvaliacao viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_HOUSI: fontSize => (
    <TypeAvaliacaoHousi viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_AVALIACAO_HOUSI: fontSize => (
    <TypeAvaliacaoHousi viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  avaliacaoHousi: fontSize => (
    <TypeAvaliacaoHousi viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_AVALIACAO_ZAP: fontSize => (
    <TypeAvaliacaoZap viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  avaliacaoZap: fontSize => <TypeAvaliacaoZap viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_CAUTELAR: fontSize => <TypeCautelar viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  cautelar: fontSize => <TypeCautelar viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_CAUTELAR_ATERPA: fontSize => (
    <TypeCautelarAterpa viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  cautelarAterpa: fontSize => (
    <TypeCautelarAterpa viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_CAUTELAR_CC_INFRA: fontSize => (
    <TypeCautelarCCInfra viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  cautelarCCInfra: fontSize => (
    <TypeCautelarCCInfra viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_CAUTELAR_QUEIROZ_GALVAO: fontSize => (
    <TypeCautelarQueirozGalvao viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  cautelarQueirozGalvao: fontSize => (
    <TypeCautelarQueirozGalvao viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_CHECKLIST_SAMSUNG: fontSize => (
    <TypeChecklistSamsung viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  checklistSamsung: fontSize => (
    <TypeChecklistSamsung viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_CONFERENCIA_FINAL_DE_OBRA: fontSize => (
    <TypeConferenciaFinalDeObra viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  conferenciaFinalDeObra: fontSize => (
    <TypeConferenciaFinalDeObra viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_ENTRADA_B_HOMY: fontSize => (
    <TypeEntradaBHomy viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  entradaBHomy: fontSize => <TypeEntradaBHomy viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_ENTRADA_LIGHT: fontSize => (
    <TypeEntradaLight viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  entradaLight: fontSize => <TypeEntradaLight viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_INVENTARIO: fontSize => (
    <TypeInventario viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  inventario: fontSize => <TypeInventario viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_PILOTO: fontSize => <TypePiloto viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  piloto: fontSize => <TypePiloto viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_PRE_VISTORIA_B_HOMY: fontSize => (
    <TypePreVistoriaBHomy viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  preVistoriaBHomy: fontSize => (
    <TypePreVistoriaBHomy viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_PREDIAL_O2: fontSize => (
    <TypePredialO2 viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  predialO2: fontSize => <TypePredialO2 viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  VISTORIA_TOMBAMENTO: fontSize => (
    <TypeTombamento viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  tombamento: fontSize => <TypeTombamento viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
  intermediariaBHomy: fontSize => (
    <TypeIntermediariaBHomy viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_INTERMEDIARIA_B_HOMY: fontSize => (
    <TypeIntermediariaBHomy viewBox={secondDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_ENTRADA_CURTA_TEMPORADA: fontSize => (
    <TypeEntradaCurtaTemporada viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  entradaCurtaTemporada: fontSize => (
    <TypeEntradaCurtaTemporada viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_CURTA_TEMPORADA: fontSize => (
    <TypeCurtaTemporada viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  curtaTemporada: fontSize => (
    <TypeCurtaTemporada viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_SAIDA_CURTA_TEMPORADA: fontSize => (
    <TypeSaidaCurtaTemporada viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  saidaCurtaTemporada: fontSize => (
    <TypeSaidaCurtaTemporada viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_ACOMPANHAMENTO_OBRA_SEAZONE: fontSize => (
    <TypeAcompanhamentoObraSeazone viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  acompanhamentoObraSeazone: fontSize => (
    <TypeAcompanhamentoObraSeazone viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_MEDICAO_OBRA_TRINUS: fontSize => (
    <TypeMedicaoObraTrinus viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  medicaoObraTrinus: fontSize => (
    <TypeMedicaoObraTrinus viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  VISTORIA_FICHA_CERTA: fontSize => (
    <TypeFichaCerta viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  marketplace_fichacerta: fontSize => (
    <TypeFichaCerta viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />
  ),
  default: fontSize => <TypeNone viewBox={firstDefaultViewBox} style={{ fontSize, ...defaultStyles }} />,
};

const OrderIcon = ({ fontSize, orderType }) => {
  return orderType in orderTypeIconMapper
    ? orderTypeIconMapper[orderType](fontSize)
    : orderTypeIconMapper.default(fontSize);
};

OrderIcon.propTypes = {
  /**
   * String of the order type
   */
  orderType: PropTypes.string,
  /**
   * Size of the icon
   */
  fontSize: PropTypes.string,
};

export default OrderIcon;
