import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const TourVirtualAcessInfo = props => (
  <SvgIcon viewBox="0 0 34.016 34.016" {...props} xmlns="http://www.w3.org/2000/svg">
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 0.46106C6.729 0.46106 0 7.19006 0 15.4611C0 23.7321 6.729 30.4611 15 30.4611C23.271 30.4611 30 23.7321 30 15.4611C30 7.19006 23.271 0.46106 15 0.46106ZM15 28.4611C7.832 28.4611 2 22.6291 2 15.4611C2 8.29306 7.832 2.46106 15 2.46106C22.168 2.46106 28 8.29306 28 15.4611C28 22.6291 22.168 28.4611 15 28.4611Z"
        fill="#393939"
      />
      <path
        d="M22.38 13.9541H21.261V10.9731C21.261 10.1531 20.59 9.48306 19.771 9.48306H16.791V8.36306C16.791 7.33506 15.957 6.50106 14.929 6.50106C13.901 6.50106 13.065 7.33506 13.065 8.36306V9.48306H10.084C9.264 9.48306 8.601 10.1531 8.601 10.9731V13.8051H9.712C10.823 13.8051 11.725 14.7071 11.725 15.8181C11.725 16.9311 10.823 17.8311 9.712 17.8311H8.594V20.6651C8.594 21.4821 9.264 22.1531 10.084 22.1531H12.917V21.0371C12.917 19.9251 13.818 19.0231 14.929 19.0231C16.039 19.0231 16.94 19.9241 16.94 21.0371V22.1531H19.772C20.591 22.1531 21.262 21.4821 21.262 20.6651V17.6831H22.381C23.408 17.6831 24.243 16.8481 24.243 15.8181C24.242 14.7891 23.407 13.9541 22.38 13.9541Z"
        fill="#393939"
      />
    </svg>
  </SvgIcon>
);

TourVirtualAcessInfo.displayName = 'TourVirtualAcessInfo';
TourVirtualAcessInfo.muiName = 'SvgIcon';

export default TourVirtualAcessInfo;
