import React from 'react';
import PropTypes from 'prop-types';

import classes from 'src/components/SchedulePage/DateInfo/style.module.scss';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import ptBR from 'date-fns/locale/pt-BR'; // the locale you want
import { registerLocale } from 'react-datepicker';

registerLocale('pt-BR', ptBR);

const DateInfoComponent = ({ date, label }) => {
  return (
    <div className={classes.dateInfoContainer}>
      <div className={classes.dateInforDisplay}>{label}</div>
    </div>
  );
};

DateInfoComponent.propTypes = {
  date: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

export default injectIntl(DateInfoComponent);
