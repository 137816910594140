import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/VacationNoticeSection/style.module.scss';
import VacationNotice from 'src/svg-icons/ReportSubmission';

const VacationNoticeSection = ({ email }) => (
  <Grid item xs={12}>
    <div className={classes.header}>
      <VacationNotice className={classes.icon} />

      <FormattedMessage id="vacationNotice" />
    </div>

    <Grid container spacing={8} className={classes.information}>
      <Grid item xs={8} data-cy="order-details-vacation-notice-tenant-email">
        <div className={classes.subHeader}>
          <FormattedMessage id="tenantEmail" />
        </div>

        {email}
      </Grid>
    </Grid>
  </Grid>
);

VacationNoticeSection.propTypes = {
  email: PropTypes.string.isRequired,
};

export default VacationNoticeSection;
