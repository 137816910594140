import axios from 'axios';

import API from 'src/apiRequest';
import { ITEMS_PER_PAGE } from 'src/constants';
import OrdersTypes from 'src/store/ducks/orders/types';

const OrdersCreators = {
  fetchOrdersRequest: () => ({
    type: OrdersTypes.FETCH_ORDERS_REQUEST,
  }),

  fetchOrdersSuccess: ({ orders, append }) => ({
    type: OrdersTypes.FETCH_ORDERS_SUCCESS,
    payload: { orders, append },
  }),

  fetchOrdersError: error => ({
    type: OrdersTypes.FETCH_ORDERS_ERROR,
    payload: error,
  }),

  fetchOrderByIdRequest: () => ({
    type: OrdersTypes.FETCH_ORDER_BY_ID_REQUEST,
  }),

  fetchOrderByIdSuccess: order => ({
    type: OrdersTypes.FETCH_ORDER_BY_ID_SUCCESS,
    payload: order,
  }),

  fetchOrderByIdError: error => ({
    type: OrdersTypes.FETCH_ORDER_BY_ID_ERROR,
    payload: error,
  }),

  clearOrders: () => ({
    type: OrdersTypes.CLEAR_ORDERS,
  }),

  clearCurrentOrder: () => ({
    type: OrdersTypes.CLEAR_CURRENT_ORDER,
  }),

  hasMoreOrders: itHas => ({
    type: OrdersTypes.HAS_MORE_ORDERS,
    payload: itHas,
  }),

  ordersLoading: isLoading => ({
    type: OrdersTypes.ORDERS_LOADING,
    payload: isLoading,
  }),

  orderStatusToSearch: status => ({
    type: OrdersTypes.ORDER_STATUS_TO_SEARCH,
    payload: status,
  }),

  orderByDir: order => ({
    type: OrdersTypes.ORDER_BY_DIR,
    payload: order,
  }),
};

/**
 * Thunk Actions
 */
export function fetchOrders({
  search,
  status,
  orderBy = 'created_at',
  limit = ITEMS_PER_PAGE,
  offset,
  tags,
  type,
  cancelToken,
  withLoading = true,
  append = true,
}) {
  return async (dispatch, getState) => {
    const { hasMoreOrders, orderStatusToSearch, orderByDir } = getState().orders;

    if (withLoading) {
      dispatch(OrdersCreators.fetchOrdersRequest());
    }

    const params = {
      status: orderStatusToSearch ? orderStatusToSearch : status,
      order_by_dir: orderByDir ? orderByDir : 'asc',
    };

    if (params.status === 'search') params.status = 'all';
    if (search) params.search = search;
    if (limit) params.limit = limit;
    if (offset) params.offset = offset;
    if (Array.isArray(tags) && tags.length) params.tags = tags;
    if (type) params.type = type;
    if (orderBy) params.order_by = orderBy;

    try {
      const { data: orders } = await API.get('/orders', { params, cancelToken });

      if (!orders.length && hasMoreOrders) {
        dispatch(OrdersCreators.hasMoreOrders(false));
        dispatch(OrdersCreators.ordersLoading(false));

        return;
      }

      if (!orders.length && !hasMoreOrders) {
        dispatch(OrdersCreators.fetchOrdersSuccess({ orders: [], append: false }));

        return;
      }

      dispatch(OrdersCreators.hasMoreOrders(true));
      dispatch(OrdersCreators.fetchOrdersSuccess({ orders, append }));
    } catch (err) {
      if (!axios.isCancel(err)) {
        dispatch(OrdersCreators.fetchOrdersError(err));

        console.error('fetchOrders', err);
      }
    }
  };
}

export function fetchOrderById(orderId) {
  return async dispatch => {
    dispatch(OrdersCreators.fetchOrderByIdRequest());

    try {
      if (!orderId || !/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(orderId)) {
        throw new Error('orderId param is required and must be a valid UUID');
      }

      const { data: order } = await API.get(`/view/orders/${orderId}`);

      dispatch(OrdersCreators.fetchOrderByIdSuccess(order));
    } catch (err) {
      dispatch(OrdersCreators.fetchOrderByIdError(err));

      console.error('fetchOrderById', err);
    }
  };
}

export function clearOrders() {
  return dispatch => dispatch(OrdersCreators.clearOrders());
}

export function clearCurrentOrder() {
  return dispatch => dispatch(OrdersCreators.clearCurrentOrder());
}

export function setHasMoreOrders(itHas) {
  return dispatch => dispatch(OrdersCreators.hasMoreOrders(itHas));
}

export function setOrdersLoading(isLoading) {
  return dispatch => dispatch(OrdersCreators.ordersLoading(isLoading));
}

export function setOrderStatusToSearch(status) {
  return dispatch => dispatch(OrdersCreators.orderStatusToSearch(status));
}

export function setOrderByDir(order) {
  return dispatch => dispatch(OrdersCreators.orderByDir(order));
}
