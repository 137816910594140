import React from 'react';

import PropTypes from 'prop-types';

import classes from './OrderCardServiceModal.module.scss';
import { Modal } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';

import { FormattedMessage } from 'react-intl';

const contactMail = 'erik.silva@redevistorias.com.br';

const OrderCardServiceModal = ({ type, isOpen, handleClose }) => {
  const ContentFcAnalise = () => {
    return (
      <>
        <div className={classes.bannerContainer}>
          <img
            src="https://s.vistori.as/assets/banner-modal-fc-analise.png"
            className="img-fluid"
            style={{ maxHeight: '100px' }}
          />
        </div>
        <div>
          <p>
            <FormattedMessage id="orderCardServiceModal.fcAnaliseText1" />
          </p>
          <p>
            <FormattedMessage id="orderCardServiceModal.fcAnaliseText2" />
          </p>
        </div>
        <div className={classes.imgContainer}>
          <div>
            <img
              src="https://s.vistori.as/assets/img-content-modal-fc-analise-1.png"
              className="img-fluid"
              style={{ maxHeight: '150px' }}
            />
          </div>
          <div>
            <img
              src="https://s.vistori.as/assets/img-content-modal-fc-analise-2.png"
              className="img-fluid"
              style={{ maxHeight: '150px', marginBottom: '15px' }}
            />
          </div>
        </div>
        <div>
          <p>
            <FormattedMessage id="orderCardServiceModal.fcAnaliseText3" />
          </p>
        </div>
        <div className={classes.imgContainer}>
          <img
            src="https://s.vistori.as/assets/img-content-modal-fc-analise-3.png"
            className="img-fluid"
            style={{ maxHeight: '140px' }}
          />
        </div>
        <div>
          <p>
            <FormattedMessage id="orderCardServiceModal.fcAnaliseText4" values={{ email: contactMail }} />
          </p>
        </div>
      </>
    );
  };

  const ContentDocusign = () => {
    return (
      <>
        <div className={classes.bannerContainer} style={{ marginTop: '0' }}>
          <img
            src="https://s.vistori.as/assets/banner-modal-docusign.png"
            className="img-fluid"
            style={{ maxHeight: '200px' }}
          />
        </div>
        <div>
          <p>
            <FormattedMessage id="orderCardServiceModal.docusignText1" />
          </p>
          <p>
            <FormattedMessage id="orderCardServiceModal.docusignText2" />
          </p>
        </div>
        <div className={classes.imgContainer}>
          <div>
            <img
              src="https://s.vistori.as/assets/img-content-modal-fc-analise-1.png"
              className="img-fluid"
              style={{ maxHeight: '150px' }}
            />
          </div>
          <div>
            <img
              src="https://s.vistori.as/assets/img-content-modal-fc-analise-2.png"
              className="img-fluid"
              style={{ maxHeight: '150px', marginBottom: '15px' }}
            />
          </div>
        </div>
        <div>
          <p>
            <FormattedMessage id="orderCardServiceModal.docusignText3" />
          </p>
          <p>
            <FormattedMessage id="orderCardServiceModal.docusignText4" values={{ email: contactMail }} />
          </p>
        </div>
      </>
    );
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpen}
      onClose={handleClose}
      className={classes.orderCardServiceModal}
    >
      <div className={classes.modal}>
        <div className={classes.modalHead}>
          <div className={classes.containerHead}>
            <NavigateBefore className={classes.icon} />
            <NavigateBefore className={classes.iconLeft} />
            <NavigateBefore className={classes.iconLeft} />
            <FormattedMessage id="orderCardServiceModal.title" />
            <NavigateNext className={classes.iconRight} />
            <NavigateNext className={classes.iconRight} />
            <NavigateNext className={classes.icon} />
          </div>
        </div>
        <div className={classes.modalBody}>
          {type === 'marketplace_fichacerta' ? (
            <ContentFcAnalise />
          ) : type === 'marketplace_docusign' ? (
            <ContentDocusign />
          ) : null}
          <div className={classes.imgFooterContainer}>
            <img src="https://s.vistori.as/assets/img-footer-modal-rvtech.png" className="img-fluid" />
          </div>
        </div>
        <div className={classes.modalFooter}></div>
      </div>
    </Modal>
  );
};

OrderCardServiceModal.propTypes = {
  type: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default OrderCardServiceModal;
