import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import DateSwitchComponent from 'src/components/SchedulePage/DateSwitch/DateSwitchComponent';
import { useConfirmAlert } from 'src/contexts/ConfirmAlertContext';
import { setDate, setInspectorsAvailabilities, setUnsaved } from 'src/store/ducks/schedule';

const DateSwitchContainer = () => {
  const { date, unsaved } = useSelector(({ schedule }) => ({
    date: schedule.date,
    unsaved: schedule.unsaved,
  }));
  const dispatch = useDispatch();
  const intl = useIntl();

  const { openConfirmAlert } = useConfirmAlert();

  const changeDate = newDate => {
    newDate.setHours(0, 0, 0, 0);
    dispatch(setDate(moment(newDate)));
    dispatch(setUnsaved(false));
  };

  const handleChange = newDate => {
    if (unsaved) {
      openConfirmAlert({
        message: intl.formatMessage({
          id: 'THERE_ARE_CHANGES_NOT_SENT_TO_DEVICES_MESSAGE',
        }),
        onConfirm: () => {
          changeDate(newDate);
          dispatch(setInspectorsAvailabilities());
        },
      });
    } else {
      changeDate(newDate);
      dispatch(setInspectorsAvailabilities());
    }
  };

  const handleNavigateDateNext = date => {
    const updatedDate = moment(date).add(1, 'day');
    const newDate = new Date(updatedDate);
    handleChange(newDate);
  };

  const handleNavigateDateBefore = date => {
    const updatedDate = moment(date).add(-1, 'day');
    const newDate = new Date(updatedDate);
    handleChange(newDate);
  };

  return <DateSwitchComponent date={date} handleChange={handleChange} handleNavigateDateNext={handleNavigateDateNext} handleNavigateDateBefore={handleNavigateDateBefore} />;
};

export default DateSwitchContainer;
