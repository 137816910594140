import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import BudgetDuringInspectionSectionGridItem from 'src/orderDetails/BudgetDuringInspectionSection/BudgetDuringInspectionSectionGridItem';
import BudgetDuringInspectionSectionGridItemDetails from 'src/orderDetails/BudgetDuringInspectionSection/BudgetDuringInspectionSectionGridItemDetails';
import classes from 'src/orderDetails/BudgetDuringInspectionSection/style.module.scss';
import BudgetDuringInspection from 'src/svg-icons/BudgetDuringInspection';

const includeBudgetEnumTranslations = {
  simple: 'includeBudgetSimple',
  termination: 'includeBudgetTermination',
  no: 'includeBudgetNo',
};

const BudgetDuringInspectionSection = ({ data }) => {
  return (
    <Grid item xs={12}>
      <div className={classes.header}>
        <BudgetDuringInspection className={classes.icon} />

        <FormattedMessage id="budgetDuringInspection" />
      </div>

      <Grid container spacing={8} className={classes.information}>
        {data?.includeBudget && data.includeBudget in includeBudgetEnumTranslations && (
          <BudgetDuringInspectionSectionGridItem
            xs={12}
            dataCy="include-budget"
            labelId="includeBudget"
            valueId={includeBudgetEnumTranslations[data.includeBudget]}
          />
        )}

        {data?.includeBudget && data.includeBudget !== 'no' && (
          <>
            <BudgetDuringInspectionSectionGridItem
              xs={12}
              dataCy="contract-negotiation"
              labelId="contractNegotiation"
              valueId={data?.contractNegotiation ? 'Yes' : 'No'}
            />
            {data?.contractNegotiationDetails && (
              <BudgetDuringInspectionSectionGridItemDetails
                xs={12}
                dataCy="contract-negotiation-details"
                labelId="DETAILS"
                value={data?.contractNegotiationDetails}
              />
            )}

            <BudgetDuringInspectionSectionGridItem
              xs={12}
              dataCy="relevant-dealings"
              labelId="relevantDealings"
              valueId={data?.relevantDealings ? 'Yes' : 'No'}
            />
            {data?.relevantDealingsDetails && (
              <BudgetDuringInspectionSectionGridItemDetails
                xs={12}
                dataCy="relevant-dealings-details"
                labelId="DETAILS"
                value={data?.relevantDealingsDetails}
              />
            )}

            <BudgetDuringInspectionSectionGridItem
              xs={12}
              dataCy="final-maintenance-fee"
              labelId="finalMaintenanceFee"
              valueId={data?.finalMaintenanceFee ? 'Yes' : 'No'}
            />
            {data?.finalMaintenanceFeeDetails && (
              <BudgetDuringInspectionSectionGridItemDetails
                xs={12}
                dataCy="final-maintenance-fee-details"
                labelId="DETAILS"
                value={data?.finalMaintenanceFeeDetails}
              />
            )}

            <BudgetDuringInspectionSectionGridItem
              xs={12}
              dataCy="authorized-final-settlement"
              labelId="authorizedFinalSettlement"
              valueId={data?.authorizedFinalSettlement ? 'Yes' : 'No'}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

BudgetDuringInspectionSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BudgetDuringInspectionSection;
