import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR'; // the locale you want
import PropTypes from 'prop-types';

import classes from 'src/components/SchedulePage/DateSwitch/style.module.scss';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';

registerLocale('pt-BR', ptBR);

const DateSwitcherComponent = ({ date, handleChange, handleNavigateDateNext, handleNavigateDateBefore }) => (
  <div className={classes.dateSwitch}>

    <div className={classes.navigateBefore} onClick={() => handleNavigateDateBefore(date)}>
      <NavigateBefore />
    </div>

    <div className={classes.datePicker}>
      <DatePicker
        id="dateSwitch"
        autoComplete="off"
        locale="pt-BR"
        dateFormat="dd/MM/yyy"
        selected={date.toDate()}
        onChange={handleChange}
        selectsStart
        todayButton="Hoje"
      />
    </div>

    <div className={classes.navigateNext} onClick={() => handleNavigateDateNext(date)}>
      <NavigateNext />
    </div>
  </div>
);

DateSwitcherComponent.propTypes = {
  date: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleNavigateDateNext: PropTypes.func.isRequired,
  handleNavigateDateBefore: PropTypes.func.isRequired,
};

export default DateSwitcherComponent;
